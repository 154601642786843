// spacing
$space-c-x: var(--space-c-x);
$space-c-t: var(--space-c-t);
$space-c-b: var(--space-c-b);

$space-xxs: var(--space-xxs);
$space-xs: var(--space-xs);
$space-s: var(--space-s);
$space-m: var(--space-m);
$space-l: var(--space-l);
$space-xl: var(--space-xl);
$space-xxl: var(--space-xxl);

$sizes: (
    'xxs': $space-xxs,
    'xs': $space-xs,
    's': $space-s,
    'm': $space-m,
    'l': $space-l,
    'xl': $space-xl,
    'xxl': $space-xxl,
);

@function hexToRgb($hex) {
    @return red($hex), green($hex), blue($hex);
}

// legacy colors
$color-p-blue: #1890ff;
$color-p-dark-blue: #020080;
$color-p-gray: #687176;
$color-p-white: #ffffff;
$color-pink: #ff0054;
$color-text-white: rgba(255, 255, 255, 0.65);
$color-text-placeholder: #bfbfbf;
$color-red: #f5222d;

$color-primary: #6ec1e4;
$color-french-pass: #bfe3ff;
$color-pattens-blue: #dbf5ff;
$color-secondary: #54595f;
$color-text-primary: #7a7a7a;
$color-text-black: #333;
$color-accent: #61ce70;
$color-charm: #d46d86;

// Portal primary colors
$color-portal-lighter-gray: #e8e8e8;
$color-portal-light-gray: #d8d8d8;
$color-white: #fff;
$color-black: #000;
$color-portal-primary: #8bd5e2;
$color-portal-secondary: #fc7070;
$color-portal-accent: #73cfdc;
$color-portal-lighter: #73cfdc91;
$color-portal-heavier: #17a0c9;
$color-portal-gray: #6f6f6f;
$color-portal-dark: #595959;
$color-portal-heavy-gray: #707070;
$color-portal-heavier-gray: #3e3e3e;
$color-portal-highlight: #dbf5ff;

:root {
    --color-primary: #{$color-portal-primary};
    --color-primary-rgb: #{hexToRgb($color-portal-primary)};
    --color-secondary: #{$color-portal-secondary};
    --color-secondary-rgb: #{hexToRgb($color-portal-secondary)};
    --color-accent: #{$color-portal-accent};
    --color-accent-rgb: #{hexToRgb($color-portal-accent)};
    --color-highlight: #{$color-portal-highlight};
    --color-highlight-rgb: #{hexToRgb($color-portal-highlight)};
    --color-lighter: #{$color-portal-lighter};
    --color-lighter-rgb: #{hexToRgb($color-portal-lighter)};
    --color-heavier: #{$color-portal-heavier};
    --color-heavier-rgb: #{hexToRgb($color-portal-heavier)};
    --color-gray: #{$color-portal-gray};
    --color-gray-rgb: #{hexToRgb($color-portal-gray)};
    --color-dark: #{$color-portal-dark};
    --color-dark-rgb: #{hexToRgb($color-portal-dark)};
    --color-heavy-gray: #{$color-portal-heavy-gray};
    --color-heavy-gray-rgb: #{hexToRgb($color-portal-heavy-gray)};
    --color-heavier-gray: #{$color-portal-heavier-gray};
    --color-heavier-gray-rgb: #{hexToRgb($color-portal-heavier-gray)};
    --color-lighter-gray: #{$color-portal-lighter-gray};
    --color-lighter-gray-rgb: #{hexToRgb($color-portal-lighter-gray)};
    --color-light-gray: #{$color-portal-light-gray};
    --color-light-gray-rgb: #{hexToRgb($color-portal-light-gray)};
    --color-white: #{$color-white};
    --color-white-rgb: #{hexToRgb($color-white)};
    --color-black: #{$color-black};
    --color-black-rgb: #{hexToRgb($color-black)};

    // New color variables
    --color-button: #{$color-portal-primary};
    --color-background: #{$color-portal-lighter-gray};
    --color-side-area: #dbf5ff;
    --color-border-portal: rgba(112, 112, 112, 1);
    --color-link: #04A4E9;
}

// Sizing
$max-width: 414px;
$min-width: 768px;
$max-with-pc: 1900px;
