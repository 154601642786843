:root {
  // container space
  --space-c-x: 2.5%;
  --space-c-t: 3rem;
  --space-c-b: 2rem;

  // space system. To override this on other screen, just override --space-unit
  --space-unit: 1rem;
  --space-xxs: calc(0.25 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-s: calc(0.75 * var(--space-unit));
  --space-m: calc(1.25 * var(--space-unit));
  --space-l: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
}

@include tablet {
  :root {
    --space-c-x: 5%;
    --space-c-t: 3rem;
    --space-c-b: 2rem;

    // override space system
    --space-unit: 1.25rem;
  }
}

@include desktop {
  :root {
    --space-c-x: 15%;
    --space-c-t: 3rem;
    --space-c-b: 3rem;

    // override space system
    --space-unit: 1.25rem;
  }
}

@include desktop-l {
  :root {
    --space-c-x: 20%;
    --space-c-t: 3rem;
    --space-c-b: 3rem;

    // override space system
    --space-unit: 1.5rem;
  }
}


