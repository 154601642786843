@mixin mobile {
    @media (max-width: 619px) {
        @content;
    }
}

@mixin mobile-l {
    @media (min-width: 400px) {
        @content;
    }
}

@mixin mobile-xl {
    @media (min-width: 568px) {
        @content;
    }
}

@mixin mobile-xxl {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin mobile-xxl-fix {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin mobile-xxl {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin tablet {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin tablet-m {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin ipad-air {
    @media (min-width: 820px) {
        @content;
    }
}

@mixin tablet-l {
    @media (min-width: 900px) {
        @content;
    }
}

@mixin tablet-xl {
    @media (min-width: 1000px) {
        @content;
    }
}

@mixin laptop-s {
    @media (min-width: 1024px) and (max-height: 680px) {
        @content;
    }
}

@mixin laptop {
    @media (min-width: 1024px) and (max-height: 780px) {
        @content;
    }
}


@mixin desktop {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin max1200 {
    @media (max-width: 1200px) {
        @content;
    }
}

@mixin desktop-l {
    @media (min-width: 1500px) {
        @content;
    }
}

@mixin desktop-xl {
    @media (min-width: 1900px) {
        @content;
    }
}

@mixin desktop-2k {
    @media (min-width: 2348px) {
        @content;
    }
}

@mixin desktop-4k {
    @media (min-width: 3500px) {
        @content;
    }
}

@mixin desktop-8k {
    @media (min-width: 7380px) {
        @content;
    }
}

@mixin title {
    font-weight: 700;
    font-size: 1.615rem;
    padding-bottom: $space-s;
    @include desktop {
        font-size: 2.368rem;
    }
    @include desktop-4k {
        font-size: 2.087rem;
    }
    @include laptop-s {
        font-size: 2rem;
    }
}

@mixin description {
    font-size: 1.316rem;
}

@mixin descriptionSmall {
    font-size: 0.789rem;
}
