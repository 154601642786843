.gacha {
    width: 100%;
    height: 100%;
    font-size: 15px;
    color: var(--color-gray);
    &__wrapper {
        position: relative;
        min-height: 100vh;
        display: flex;
        align-content: flex-start;
        justify-content: center;
    }
    &__container {
        background: $color-white;
        width: 100%;
        @include laptop {
            max-width: $max-with-pc;
        }
        @include mobile-xxl {
            max-width: 390px;
            margin: 0 auto;
        }
    }
    &__bottom-navigator {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 99;
        width: 100%;
        &__wrapper {
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: rgba(var(--color-white-rgb), 0.96);
            width: 100%;
            @include mobile-l {
                max-width: $max-width;
            }
            @include laptop {
                max-width: $max-with-pc;
            }
        }
        &__item {
            &.active {
                color: var(--color-primary);
            }
            // width equal to the widest icon
            width: 72px;
            height: 75px;
            display: flex;
            justify-content: center;
            padding: 16px 0 10px;
            &:hover {
                background-color: rgba(var(--color-black-rgb), 0.1);
            }
            &__link {
                display: flex;
                flex-direction: column;
                font-size: 24px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }
            &-text {
                font-size: 15px;
                margin-top: 2px;
            }
        }
    }
    &__header {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 99;
        width: 100%;
        height: 150px;
        &__wrapper {
            margin: 0 auto;
            background-color: $color-black;
            padding: 15px 0 0 0;
            width: 100%;
            @include laptop {
                max-width: $max-with-pc;
            }
        }
        &__fixed-content {
            &__container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                position: relative;
            }
            &__menu {
                position: absolute;
                right: 32px;
                display: flex;
                align-items: center;
                padding-top: 4px;
            }
        }
        &__side-left {
            position: absolute;
            left: 0;
            padding: 0 0 0 20px;
            &-back {
                &__button {
                    background-color: inherit;
                    border: none;
                    display: flex;
                    align-items: center;
                    &__icon {
                        svg {
                            cursor: pointer;
                            fill: var(--color-gray);
                        }
                    }
                }
            }
        }
        &__navigation {
            width: 100%;
            padding: 10px 30px;
            &-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
            &-button {
                font-size: 30px;
                color: var(--color-primary);
                &__icon {
                    vertical-align: 0.125rem;
                }
            }
            &-search {
                display: inline-block;
                width: 100%;
                margin-left: 30px;
            }
        }
    }
    &__logo {
        font-size: 24px;
        &__container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__icon {
            color: var(--color-primary);
        }
        &__text {
            text-transform: uppercase;
            font-weight: bolder;
            margin-left: 5px;
            font-size: 20px;
            &-beta {
                font-size: 20px;
                font-weight: bolder;
                margin-left: 5px;
            }
        }
    }
    &-page {
        &__container {
            margin-top: 115px;
        }
    }
    &__btn {
        width: 100%;
        padding: 7px 0 7px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
        background-color: unset;
        border: 1px solid var(--color-heavy-gray);
        &-text {
            color: var(--color-heavy-gray);
            font-size: 16px;
            margin-left: 32px;
        }
        &-icon {
            color: var(--color-heavy-gray);
            margin-right: 16px;
        }
        &-rounded {
            border-radius: 70px;
        }
    }
    &__link {
        width: 100%;
        padding: 7px 0 7px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
        background-color: unset;
        border: 2px solid var(--color-heavy-gray);
        &-text {
            color: var(--color-heavy-gray);
            font-size: 16px;
            margin-left: 32px;
            font-weight: bold;
        }
        &-icon {
            color: var(--color-heavy-gray);
            margin-right: 16px;
        }
        &__disable {
            position: relative;
        }
        &__disable::before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.5);
        }
    }
    &__footer {
        width: 100%;
        font-size: 15px;
        background-color: var(--color-black);
        color: var(--color-lighter-gray);
        padding-bottom: 120px;
        padding-top: 32px;
        @include mobile-xxl {
            font-size: 10px;
        }
        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 12px;
        }
        &__item {
            display: flex;
            justify-content: center;
            column-gap: 12px;
            &-underlined {
                text-decoration: underline;
            }
        }
    }
    &__input {
        &-search {
            border: 1px solid var(--color-primary);
            border-radius: 20px;
            box-shadow: var(--color-lighter) 0 0 7px;
            & > span {
                color: var(--color-light-gray);
            }
        }
    }
    &__bordered-btn {
        display: block;
        position: relative;
        margin: 36px auto;
        width: 350px;
        border: 1px solid $color-white;
        text-align: center;
        color: $color-p-white;
        font-weight: 900;
        text-transform: uppercase;
        font-size: 25px;
        letter-spacing: -2px;
        background: black;
        border-radius: 60px;
        padding: 20px 0;
        height: 75px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid $color-white;
            transition: all 0.4s;
        }

        &:hover {
            &::after {
                right: 10px;
            }
        }

        &__disabled {
            opacity: 0.5;

            &:hover {
                cursor: not-allowed;

                &::after {
                    right: 0px;
                }
            }
        }
    }
    &__filled-btn {
        display: block;
        position: relative;
        padding: 20px 30px;
        margin: 36px auto;
        width: 350px;
        border-radius: 22px;
        background-color: rgba(0, 0, 0, 1);
        text-align: center;
        color: #fff;
        font-weight: 900;
        text-transform: uppercase;
        font-size: 22px;
        letter-spacing: -2px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #fff;
            transition: all 0.4s;
        }

        &:hover {
            color: #fff;
            &::after {
                right: 10px;
            }
        }
        &__disabled {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
    &__underlined-link {
        display: block;
        width: 100%;
        margin: 36px auto;
        text-align: center;

        &__text {
            display: inline-block;
            position: relative;
            border-bottom: 1px solid var(--color-heavy-gray);
            padding-right: 20px;
            color: var(--color-heavy-gray);
            font-weight: 900;
            text-transform: uppercase;
            font-size: 22px;
            letter-spacing: -2px;
            transition: all 0.4s;

            &::after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 10px solid var(--color-heavy-gray);
            }
        }
    }
    &__fullwidth-btn {
        display: block;
        position: relative;
        margin: 36px auto;
        width: 100%;
        border: 1px solid var(--color-heavy-gray);
        text-align: center;
        color: $color-p-white;
        font-weight: 900;
        text-transform: uppercase;
        font-size: 22px;
        letter-spacing: -2px;
        background: $color-black;
        border-radius: 60px;

        &:hover {
            text-transform: uppercase;
        }
    }
}

// change text of cancel btn in modal made by antDesign from en to ja
.ant-modal {
    &-confirm-btns {
        button:nth-of-type(1) {
            padding: 0 7.5px;
            width: 75px;
            span {
                font-size: 0;
                &:before {
                    font-size: 12px;
                    content: '\30AD\30E3\30F3\30BB\30EB'; //convert japanese word "キャンセル" (means cansele) into Unicode character
                }
            }
        }
    }
}
.padding-btn-play {
    padding: 20px 30px;
    font-family: "Pyumin Pro",sans-serif;
}

.padding-btn-play-1 {
    padding: 6.5px 5px;
    line-height: 1.2;
    font-family: "Pyumin Pro",sans-serif;
}

@media screen and (max-width: 767.9px) {
    .gacha {
        &__bordered-btn {
            width: 300px;
            font-size: 22px;
        }

        &__filled-btn {
            width: 300px;
            font-size: 22px;
        }
    }
}

.h3 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bolder;
}