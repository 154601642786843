.portal-news {
    width: 100%;
    margin-top: 25px;

    @include mobile {
        padding-left: 15px;
        padding-right: 15px;
    }

    @include tablet {
        margin-top: 50px;
    }

    &__wrapper {
        margin-bottom: 36px;
    }

    &__content {
        margin-top: 20px;

        @include tablet {
            display: flex;
            align-items: flex-start;
            padding: 52px 44px 0;
        }

        &__image {
            width: 100%;
        }

        &__item {
            @include tablet {
                width: 50%;
            }
        }
    }

    &__list__wrapper {
        margin-left: 12px;

        @include desktop {
            margin-left: 24px;
        }
    }

    &__list {
        &__wrapper {
            @include tablet {
                margin-right: 50px;
            }
        }

        &__item {
            margin-bottom: 16px;
            text-decoration: underline;

            &__link {
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

                @include mobile {
                    margin: 0;
                }

                &-date {
                    span {
                        font-size: 14px;
                        color: var(--color-dark);

                        @include tablet {
                            font-size: 14px;
                        }
                    }
                }

                &-title {
                    span {
                        font-size: 16px;
                        font-weight: 500;
                        color: var(--color-black);

                        @include tablet {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }

    &-detail {
        &__link {
            margin-top: 50px;

            &__top {
                margin-top: 15px;
            }
        }

        &__content {
            img {
                max-width: 100%;
            }
        }
    }

    &__heading {
        p {
            color: var(--color-white);
        }
    }

    &__btn {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-decoration: underline;

        @include tablet {
            font-size: 20px;
        }

        &:hover,
        &:focus,
        &:active {
            box-shadow: transparent 0 0 5px;
        }

        &__container {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;

            @include tablet {
                margin-top: 50px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    &__index {
        &__list {
            margin-bottom: 20px;

            &__item {
                padding: 0 6px 10px 6px;
                margin-bottom: 10px;

                &__container {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    &:hover,
                    &:focus,
                    &:active {

                        p,
                        span {
                            color: var(--color-light-dark);
                        }
                    }
                }

                &__title {
                    p {
                        font-size: 18px;
                        color: var(--color-link);
                        font-weight: bold;
                    }
                }

                &__publish-at {
                    span {
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }
        }

        &__paginator {
            margin-bottom: 20px;

            &__container {
                .ant-pagination {
                    display: flex;
                    justify-content: center;

                    &-prev {
                        margin-right: 14px;

                        button {
                            color: var(--color-white);

                            &:hover,
                            &:focus,
                            &:active {
                                color: var(--color-white);
                                border: 1px solid var(--color-black);
                            }
                        }
                    }

                    &-item {
                        border: 1px solid var(--color-black);
                        box-shadow: transparent 0 0 5px;
                        margin-right: 14px;

                        &:hover,
                        &:focus,
                        &:active {
                            background-color: var(--color-black);
                            border: 1px solid var(--color-black);
                        }

                        a {
                            color: var(--color-black);

                            &:hover,
                            &:focus,
                            &:active {
                                color: var(--color-white);
                                background-color: var(--color-black);
                                border: 1px solid var(--color-black);
                            }
                        }

                        &-link {
                            border: 1px solid var(--color-black);
                            box-shadow: transparent 0 0 5px;

                            &:hover,
                            &:focus,
                            &:active {
                                background-color: var(--color-black);
                            }
                        }

                        &-active {
                            background-color: var(--color-black);

                            a {
                                color: var(--color-white);
                            }
                        }
                    }

                    &-next {
                        margin-right: 14px;

                        button {
                            color: var(--color-black);

                            &:hover,
                            &:focus,
                            &:active {
                                color: var(--color-white);
                                border: 1px solid var(--color-black);
                            }
                        }
                    }

                    &-disabled {
                        .ant-pagination-item-link {
                            color: var(--color-light-gray);
                            border-color: var(--color-light-gray);
                            box-shadow: none;

                            &:hover,
                            &:focus,
                            &:active {
                                color: var(--color-light-gray);
                                border-color: var(--color-light-gray);
                                background-color: var(--color-white);
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.portal-news-banner-img {
    @include mobile {
        height: auto;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @include tablet {
        height: 165px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @include desktop {
        height: 210px;
        background-repeat: no-repeat;
        background-size: cover;
    }
}