@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'mixins';
@import 'spacing';
@import 'variables';
@import 'modules/index';
@import 'components/index';
//Please add more modules here

//Please do not add more modules below this line
@import 'utils';

// font
@font-face {
    font-family: 'Pyumin Pro';
    src: URL('../font/RyuminProNormal.otf');
}

@font-face {
    font-family: 'Pyumin Pro Bold';
    src: URL('../font/RyuminProBold.otf');
}

@font-face {
    font-family: Krungthep;
    src: URL('../font/Krungthep.ttf');
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background: var(--color-white);
    color: var(--color-black);
}

html {
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        text-transform: none;
        color: inherit;
    }
}

article {

    div,
    img,
    video {
        all: initial;
    }
}

.app {
    // background-color: transparent;
    // background-image: linear-gradient(0deg, var(--color-lighter) 0%, var(--color-highlight) 100%);
    background-color: white;
}

.font-ryumin-bold {
    font-family: 'Pyumin Pro Bold', sans-serif;
}

.font-ryumin-normal {
    font-family: 'Pyumin Pro', sans-serif;
}

@for $i from 0 through 500 {
    .zIndex-#{$i} {
        z-index: #{$i} !important;
    }

    .left-#{$i} {
        left: #{$i}px !important;
    }

    .right-#{$i} {
        right: #{$i}px !important;
    }

    .top-#{$i} {
        top: #{$i}px;
    }

    .font-size-#{$i} {
        font-size: #{$i}px;
    }

    .p-#{$i} {
        padding: #{$i}px;
    }

    .pt-#{$i} {
        padding-top: #{$i}px;
    }

    .pl-#{$i} {
        padding-left: #{$i}px;
    }

    .pb-#{$i} {
        padding-bottom: #{$i}px;
    }

    .pr-#{$i} {
        padding-right: #{$i}px;
    }

    .m-#{$i} {
        margin: #{$i}px;
    }

    .mb-#{$i} {
        margin-bottom: #{$i}px;
    }

    .mb-#{$i}-i {
        margin-bottom: #{$i}px !important;
    }

    .mt-#{$i} {
        margin-top: #{$i}px;
    }

    .mt-#{$i}-i {
        margin-top: #{$i}px !important;
    }

    .ml-#{$i} {
        margin-left: #{$i}px;
    }

    .ml-#{$i}-i {
        margin-left: #{$i}px !important;
    }

    .mr-#{$i} {
        margin-right: #{$i}px;
    }

    .mr-#{$i}-i {
        margin-right: #{$i}px !important;
    }

    .gap-#{$i} {
        gap: #{$i}px;
    }

    .w-#{$i} {
        width: #{$i}px;
    }

    .h-#{$i} {
        height: #{$i}px;
    }

    .m--#{i} {
        margin: #{$i}px;
    }

    .radius-#{$i} {
        border-radius: #{$i}px;
    }

    .text-weight-#{$i} {
        font-weight: #{$i};
    }

    .padding-top-bottom-#{$i}-i {
        padding-top: #{$i}px !important;
        padding-bottom: #{$i}px !important;
    }

    .padding-left-right-#{$i}-i {
        padding-left: #{$i}px !important;
        padding-right: #{$i}px !important;
    }

    .radius-#{$i}-i {
        border-radius: #{$i}px !important;
    }

    .letter-spacing-#{$i} {
        letter-spacing: #{$i}px;
    }

    .letter-spacing-#{$i}-i {
        letter-spacing: #{$i}px !important;
    }

    .text-black {
        color: black;
    }

    .text-artist-name {
        color: #3e3e3e !important;
    }

    .text-header-color {
        color: #6f6f6f;
    }

    .text-shadow {
        text-shadow: #000 1px 0 10px;
    }

    .font-size-#{$i}-i {
        font-size: #{$i}px !important;
    }

    .mb-#{$i}-i {
        margin-bottom: #{$i}px !important;
    }

    .line-height-#{$i} {
        line-height: #{$i}px !important;
    }

    .width100 {
        width: 100%;
    }

    @include mobile {
        .gap-sp-#{$i} {
            gap: #{$i}px;
        }

        .ml-sp-#{$i} {
            margin-left: #{$i}px !important;
        }

        .mr-sp-#{$i} {
            margin-right: #{$i}px !important;
        }

        .font-size-sp-#{$i} {
            font-size: #{$i}px !important;
        }
    }
}

.flex {
    display: flex;

    @include mobile-l {
        justify-content: start;
    }

    @include mobile {
        justify-content: start;
    }

    @include tablet {
        justify-content: center;
    }

    @include desktop {
        justify-content: center;
    }
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-start {
    justify-content: start;
}

.justify-end {
    justify-content: end;
}

.align-end {
    align-items: end;
}

.justify-center-sp {
    @include mobile {
        justify-content: center;
    }
}

.justify-evenly {
    justify-content: space-evenly;
}

.grid {
    display: grid;
}

.items-center {
    align-items: center;
}

.bg-red {
    background-color: red;
}

.bg-black {
    background-color: #000 !important;
}

.bg-gray {
    background-color: #979797 !important;
}

.border-r-none {
    border-right: none;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-bold {
    font-weight: bold;
}

.custom-text {
    font-weight: bold;

    a {
        color: blue;

        &:hover {
            text-decoration: underline;
        }
    }
}

.color-black {
    color: var(--color-black);
}

.color-gray {
    color: #979797;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.portal-heading-artist {
    background-color: var(--color-white);
    border-radius: 70px;

    p {
        color: var(--color-black);
        font-size: 24px;
        line-height: 21px;
        font-weight: 700;

        @include tablet {
            font-size: 28px;
            line-height: 28px;
        }

        @include desktop {
            font-size: 28px;
            line-height: 28px;
        }
    }

    @include tablet {
        padding: 8px;
        padding-left: 0px;
    }
}

.relative {
    position: relative;
}

.absolute {
    position: absolute !important;
}

ml-85 {
    margin-left: 0;

    @include desktop {
        margin-left: 86 !important;
    }
}

.btn-div-heart-icon {
    display: flex;
    justify-content: center;

    .img-heart {
        padding: 7px 0px;
        margin-right: 7px;
    }
}

.group-btn-favorite {
    color: var(--color-white);
    border: 1px solid var(--color-white);
    background-color: #f05454;
}

.ant-divider {
    border-top: none;
}

.link-title {
    font-size: 16px !important;
    font-weight: 700;
}

.br-new-ink {
    @include desktop {
        display: none;
    }

    @include mobile {
        display: flex;
    }
}

.group-news-list {
    padding-top: 20px !important;
}

.group-heart-action {
    margin-top: 7px;
    margin-right: 7px;
}

.mt-2 {
    margin-top: 5px;
}

.createList-center {
    text-align: center;
}

.d-none {
    display: none;
}

.text-none-data {
    font-size: 11px;
    letter-spacing: 2px;
    line-height: 38px;
    text-decoration: underline;
    color: #000000;
    font-family: 'Koz Go Pr6 N';
    font-weight: 700;
}

.portal-groups-banner {
    @include desktop {
        padding-top: 80px;
    }
}

.div-link-text {
    width: 100%;
    text-align: right;
    margin-bottom: 15px;

    .link-text {
        text-decoration: underline;
        margin-right: 15px;
        font-size: 14px;
    }
}

.link-text-a {
    margin-right: 0px;
    font-size: 14px;
    text-decoration: underline;
    padding-top: 10px;

    @include tablet {
        padding-top: 25px;
        font-size: 20px;
    }

    font-family: -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
    font-weight: 500;
}

.row-shop-artist-list {
    flex-wrap: wrap;
}

.white-space {
    white-space: pre-wrap;
}

.margin-center {
    margin: 0 auto;
}

.h-full {
    height: 100%;
}

.text-ellipsis {
    text-overflow: ellipsis;
}

.ml-0-i {
    padding-left: 0px;
}

.magin-news {
    @include tablet {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.magin-nft-top {
    @include tablet {
        margin-left: 15px;
    }
}

.padding-member-group-header {
    @include tablet {
        padding-left: 0 !important;
    }
}

.padding-top-25 {
    @include tablet {
        padding-top: 25px !important;
    }
}

.margin-top-pc {
    @include tablet {
        margin-top: 36px;
    }
}

.magin-bottom-link-public {
    margin-bottom: 2px;
}

.carousel-top-page-dots-style {
    // background-color: red;
    height: 20px;
    position: absolute;
    bottom: -50px !important;
    display: flex;
    gap: 2px;

    li {
        width: 20px !important;
        height: 20px !important;

        @include mobile {
            width: 15px !important;
            height: 15px !important;
        }
    }

    li button {
        margin: 0 !important;
        padding: 0 !important;
        width: 20px !important;
        height: 20px !important;
        border-radius: 50% !important;
        border: 2px solid gray !important;

        @include mobile {
            width: 15px !important;
            height: 15px !important;
        }
    }

    li.slick-active button {
        margin: 0 !important;
        padding: 0 !important;
        width: 20px !important;
        height: 20px !important;
        border-radius: 50% !important;
        background: gray !important;
        border: 2px solid gray;

        @include mobile {
            width: 15px !important;
            height: 15px !important;
        }
    }
}

.box-shadow-unset {
    box-shadow: unset !important;
}

.paginate-style-in-page-2 {
    .ant-pagination {
        .ant-pagination-prev {
            .ant-pagination-item-link {
                color: black !important;
            }
        }
    }
}

.btn-see-more {
    font-size: 14px !important;
    color: #6f6f6f;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
    text-decoration: underline;
    font-weight: 500;

    @include desktop {
        font-size: 20px !important;
    }
}

.link-setting-page {
    font-size: 14px !important;
    // color: #6f6f6f;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
    text-decoration: underline;
    font-weight: 500;
    button {
        text-decoration: inherit;
    }
    button:hover {
        text-decoration: none;
    }
}

.link-no-href {
    margin-right: 38px;

    & button {
        text-decoration: underline;

        &:hover {
            cursor: pointer;
            text-decoration: none !important;
        }
    }
}

.div-link-set-page {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: right;

    & a {
        margin-right: 10px;
    }
}

.div-link-bottom {
    width: 100%;
    text-align: right;
    align-content: space-around;
    margin-top: 20px;

    // & a {
    //     margin-right: 40px;
    // }
}

.img-block {
    width: 100%;
    background-color: #000000;
    height: 100px;
    display: flex;

    img {
        width: 100px;
        min-width: 100px;
    }

    @include tablet {
        height: 230px;

        img {
            width: 230px;
            min-width: 230px;
        }
    }
}

.txt-content-ticket {
    font-family: Times New Roman;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    margin: 0 10px 0 10px;

    p {
        line-height: 22px;
    }

    @include tablet {
        font-size: 18px;
        margin: 25px 25px 0 25px;
        line-height: 16px;
    }
}

.ticket-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    a {
        border: 1px solid #ffffff;
    }

    @include tablet {
        gap: 65px;
    }

    @include mobile {
        grid-template-columns: repeat(1, 1fr);
    }
}

.ticket-img {
    width: 45%;
    object-fit: cover;
    height: 100px;

    @include tablet {
        height: 230px;
    }
}

.member-mg-bot {
    margin-bottom: 30px !important;
}

.ant-pagination-options {
    display: none !important;
}

.artist-live-ticket-style {
    @include mobile {
        flex-direction: column !important;
        justify-content: space-between !important;

        .ant-list-item-meta {
            width: 100%;
        }

        .ant-list-item-action {
            margin-top: 10px;
            width: 100%;
            justify-content: end;
        }
    }
}

.row-nft-items {
    flex-wrap: wrap;
}

.text-nft-name {
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
}

.h-22 {
    height: 22.5px;
}

.ant-upload-list {
    display: none;
}

.ql-snow {
    .ql-picker {
        &.ql-size {

            .ql-picker-label,
            .ql-picker-item {
                &::before {
                    content: attr(data-value) !important;
                }
            }
        }
    }
}

.ant-switch-checked {
    background-color: black;
}

.ant-popover-inner-content {
    padding: 5px 10px !important;
}

.MuiTiptap-ControlledBubbleMenu-root,
.base-Popper-root {
    z-index: 1100 !important;
}