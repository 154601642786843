.portal-groups-group-image {
    width: 100%;
    display: flex;
    background-color: var(--color-white);
    &-large {
        justify-content: center;
        padding-top: 10px;
    }
    &-small {
        justify-content: start;
        padding-top: 5px;
    }
    &__container {
        overflow: hidden;
        &-large {
            width: 100%;
            max-height: 70vh;
            margin-bottom: 20px;
        }
        &-small {
            max-height: 60px;
            max-width: 90px;
            margin-left: 10px;
            margin-bottom: 15px;
        }
    }
    &__image {
        width: 100%;
        @include desktop {
            width: 1170px;
        }
        height: 100%;
        overflow: hidden;
        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
}
