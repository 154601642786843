@mixin portal-faq__btn {
    font-size: 14px;
    color: var(--color-black);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    transition: all 0.25s ease-in;
    @content;
}

.portal-faq {
    &__nav {
        &__wrapper {
            width: 100%;
            margin-bottom: 15px;
        }
        &__container {
            margin-top: 15px;
            display: flex;
            align-items: center;
        }
        &__form {
            margin-right: 10px;

            > .portal__input-search {
                box-shadow: none;
                border: 1px solid var(--color-black);

                &:hover {
                    border: 1px solid var(--color-black);
                }
            }
        }
        &__btn {
            &-clear {
                @include portal-faq__btn() {
                    padding: 2px 10px;
                    &:active,
                    &:hover {
                        color: var(--color-white);
                        background-color: var(--color-black);
                        border: 1px solid var(--color-black);
                    }
                    &:focus {
                        color: var(--color-black);
                        background-color: var(--color-white);
                        border: 1px solid var(--color-black);
                    }
                }
            }
        }
    }
    &__category {
        &__wrapper {
            width: 100%;
        }
        &__container {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            margin-left: -5px;
        }
        &__item {
            margin: 5px;
            &__btn {
                @include portal-faq__btn() {
                    &:active,
                    &:hover,
                    &:focus {
                        color: var(--color-white);
                        background-color: var(--color-black);
                        border: 1px solid var(--color-black);
                    }
                }
                &-active {
                    @include portal-faq__btn() {
                        color: var(--color-white);
                        background-color: var(--color-black);
                        &:active,
                        &:hover,
                        &:focus {
                            color: var(--color-white);
                            background-color: var(--color-black);
                            border: 1px solid var(--color-black);
                        }
                    }
                }
            }
        }
    }
    &__list {
        width: 100%;
        padding-top: 10px;
        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 15px 0;
            h3 {
                font-size: 18px;
                font-weight: bold;
                line-height: 100%;
                margin-right: 5px;
                color: var(--color-black);
                &:hover {
                    color: var(--color-dark);
                }
            }
        }
    }
    &__faq {
        &__container {
            width: 100%;
            display: flex;
            justify-content: start;
            margin-bottom: 15px;
            h3 {
                font-size: 18px;
                font-weight: bold;
                color: var(--color-dark);
            }
            p {
                font-size: 14px;
            }
        }
        &__identifier {
            font-size: 20px;
            font-weight: bold;
            line-height: 20px;
            height: 30px;
            padding: 5px 7px;
            margin-right: 5px;
            color: var(--color-white);
            &-question {
                background-color: var(--color-primary);
            }
            &-answer {
                background-color: var(--color-gray);
            }
        }
        &__subdata {
            font-size: 14px;
            margin-right: 5px;
        }
    }
    &__paginator {
        &__container {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        &__btn {
            @include portal-faq__btn() {
                margin: 10px 5px;
                &:active,
                &:hover {
                    color: var(--color-white);
                    background-color: var(--color-primary);
                    border: 1px solid var(--color-primary);
                }
                &:focus {
                    color: var(--color-primary);
                    background-color: var(--color-white);
                    border: 1px solid var(--color-primary);
                }
            }
            &-center {
                display: flex;
                align-items: center;
            }
            &-disabled {
                color: var(--color-light-gray);
                background-color: var(--color-lighter-gray);
            }
        }
    }
    &__tag {
        font-size: 12px;
        line-height: 100%;
        color: var(--color-white);
        background-color: var(--color-black);
        padding: 5px;
        border-radius: 10px;
        white-space: nowrap;
    }
}