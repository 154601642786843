.portal-artist-list-page {
    min-height: 100vh;
    padding-top: 115px;
    
    @include mobile {
        padding-top: 50px !important;
    }
    .portal__page__header {
        @include mobile {
            margin-bottom: 10px;
        }
    }
    &__container {
        padding: 10px 15px;
        border-radius: 8px;
    }
    &__row {
        width: 200px;
        margin-bottom: 10px;
        padding: 8px 0;
        background-color: var(--color-white);
        border: solid 1px var(--color-black);
        text-align: center;
        border-radius: 70px;
        p {
            color: var(--color-black);
            font-size: 14px;
            line-height: 21px;
        }
    }
}