.portal-category-area {
    &__wrapper {
        margin: 0 20px;
        margin-bottom: 15px;
        padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        &-container {
            width: 50%;
            padding: 0 5px;
            margin-bottom: 7px;
        }
        &-arrow {
            position: absolute;
            right: 15px;
            color: var(--color-heavier);
            font-weight: bold;
        }
        font-size: 13px;
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
        background-color: var(--color-white);
        border-radius: 18px;
        box-shadow: var(--color-lighter) 0 0 5px;
        padding: 7px 0 7px 20px;
        position: relative;
        &:active,
        &:hover,
        &:focus {
            color: var(--color-white);
            background-color: var(--color-primary);
        }
        //button {
            //width: 100%;
        //}
    }
}