.portal-favorites {
  min-height: 100vh;
  padding-top: 30px;
  @include mobile {
    padding-top: 45px;
  }
  max-width: 1200px;
  margin: 0 auto;

  &__container {
    padding: 15px;
    border-radius: 8px;
    .ant-row {
      flex-flow: wrap
    }
    .ant-card-body {
      @include mobile {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }
    .ant-card-bordered {
      border: 1px solid #ffffff;
  }
  }

  &__header {
    margin-bottom: 20px;

    h3 {
      color: var(--color-dark);
      margin: 0;
      padding: 0;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: bolder;
    }

    .heart-icon {
      color: var(--color-secondary);
      vertical-align: 2px;
    }
  }

  &__item {
    border-radius: 4px;
    margin-bottom: 10px;
    transition: all 0.25s ease-in;

    .ant-card-body {
      padding: 10px;
    }

    .favorite-item {
      &__wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__avatar {
        flex: 0 0 60%;
        max-width: 70%;
        width: 200px;
        height: 200px;
        @include mobile {
          flex: 0 0 40%;
          height: 110px;
          width: 140px;
          max-width: 100%;
        }
        @include mobile-l {
          flex: 0 0 50%;
          max-width: 70%;
          width: 200px;
          height: 160px;
        }
        @include tablet {
          flex: 0 0 50%;
          max-width: 70%;
          width: 200px;
          height: 160px;
        }
        @include desktop {
          flex: 0 0 60%;
          max-width: 100%;
          width: 240px;
          height: 200px;
        }
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 100%;
          width: auto;
        }
      }

      &__content {
        flex: 0 0 26%;
        max-width: 40%;
        padding-right: 10px;
        padding-top: 10px;
        @include mobile {
          flex: 0 0 36%;
        }
        @include tablet {
          flex: 0 0 26%;
        }
        &-header {
          max-width: 100%;
          text-decoration: underline;
          a {
            // padding-right: 10px;
            // margin-left: -10px;
            word-wrap: break-word;
            font-size: 16px;
          }
          @include tablet {
            max-width: 85%;
          }
        }

        &-body {
          word-wrap: break-word;

          .favorite-item__links {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bolder;
            color: var(--color-secondary);
            margin-left: 5px;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      &__status {
        flex: 0 0 14%;
        max-width: 14%;
        display: flex;
        align-items: center;
      }

      &__actions {
        svg {
          vertical-align: unset;
        }

        &__btn {
            position: absolute;
            right: 0;
            top: 50%;
            @include mobile {
              right: -14px;
            }
            transform: translateY(-50%);
            text-align: center;
          &-unfavorite {
            color: var(--color-primary);
            border-color: var(--color-primary);
            color: var(--color-white);
            background-color: #444444;
          }
          p {
            font-size: 7px;
            margin-top: 1px;
          }
        }
      }
    }
  }
}