.portal-special {
    &__wrapper {
        margin-bottom: 50px;
        padding-left: 15px;
        padding-right: 15px;
        @include tablet {
            margin-bottom: 70px;
        }
    }
    &__list {
        position: relative;
        padding: 0 16px;
        @include tablet {
            padding: 0;
            display: flex;
        }
        &__item {
            position: relative;
            height: 80px;
            margin-top: 10px;
            border: 1px solid var(--color-border-portal);
            @include tablet {
                height: 170px;
                // width: 90%;
                margin-top: 0;
                &:nth-child(2n) {
                    margin-left: 2px;
                    margin-right: 2px;
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
            // &::after {
            //     position: absolute;
            //     content: '';
            //     width: 34px;
            //     height: 36px;
            //     right: 0px;
            //     bottom: 9%;
            //     background-color: var(--color-white);
            //     border-radius: 50%;
            //     transform: translateX(50%);
            //     border: 1px solid var(--color-border-portal);
            //     @include tablet {
            //         width: 22px;
            //         height: 24px;
            //         right: 0px;
            //     }
            // }
            // &::before {
            //     position: absolute;
            //     content: '';
            //     width: 20px;
            //     height: 40px;
            //     right: 0px;
            //     bottom: 9%;
            //     transform: translateX(104%);
            //     background-color: var(--color-white);
            //     z-index: 2;
            //     @include tablet {
            //         width: 12px;
            //         height: 30px;
            //         right: -1px;
            //         bottom: 8%;
            //         transform: translateX(100%);
            //     }
            // }
            &__pc {
                display: none;
                @include tablet {
                    display: block;
                    &__btn-next {
                        display: block;
                        height: 20px;
                        width: 24px;
                        position: absolute;
                        right: 4px;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                        img {
                            width: 100%;
                        }
                    }
                    &__btn-prev {
                        display: block;
                        height: 20px;
                        width: 24px;
                        position: absolute;
                        left: 4px;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &__heading {
        display: flex;
        margin-bottom: 34px;
        p {
            color: var(--color-white);
        }
        img {
            height: 18px;
            margin-left: 6px;
            @include tablet {
                height: 26px;
                margin-left: 10px;
            }
        }
    }
}
.special-wrapper-fix {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    row-gap: 10px;

    .special-image-wrappper {
        width: 18%;
        height: auto;
        max-height: 150px;
        max-width: unset;

        @include mobile {
            height: 100px;
            width: auto;
            max-width: 120px;
        }

        img {
            width: 100%;
            height: 100%;
            // object-fit: cover;
            object-position: top;
        }
    }
}
