.portal-profile {
  &__container {
    margin: 0 15px;
  }

  &__user-info {

  }
}

.portal-user-info {
  &__container {
    margin: 0 30px;
  }
  .gutter-row {
    margin: 15px 0;
  }

  .ant-divider {
    margin: 18px 0;
  }

  &__profile {
    &__button {
      background-color: var(--color-primary);
      color: var(--color-white);
      border: none;
      padding: 4px 20px;

      &:hover,
      &:active,
      &:focus {
        background-color: var(--color-primary);
        color: var(--color-white);
        border: none;
      }
    }
  }
}