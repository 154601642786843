.revenue__page {
	width: 100%;
	margin-top: 10px;
	min-height: calc(100vh - 132px - 67px);
	color: black;

	@include mobile-l {
		padding: 80px 10px 0;
	}
	@include mobile {
		padding: 70px 10px 0;
  	}
	@include tablet {
		padding-top: 65px;
		min-height: calc(100vh - 194px);
		width: 90%;
		max-width: 1170px;
		margin: 0 auto;
		font-size: 16px;
	}
	// @include desktop {
	// 	padding-top: 115px;
	// 	// min-height: calc(100vh - 194px);
	// }

	&_header {
		font-size: 16px;
		color: black;
		white-space: nowrap;
		overflow: hidden;
		font-weight: 700;
		text-align: center;
		margin-bottom: 40px;
		@include tablet {
			margin-top: 64px;
			font-size: 24px;
		}
	}
	&-box-title {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		height: 32px;
		align-items: center;
		cursor: pointer;
		width: 100%;
	}
	&-box-title:last-child {
		border-bottom: none;
	}
	&-title {
		font-weight: 400;
		font-size: 14px;
		color: black;
		@include tablet {
			font-size: 16px;
		}
	}
	
	&-arrow {
		width: 12px;
		height: 12px;
	}

	&_detail_header {
		font-size: 16px;
		color: black;
		white-space: nowrap;
		overflow: hidden;
		font-weight: 700;
		text-align: center;
		@include tablet {
			font-size: 24px;
		}
	}
	&-title__header {
		position: relative;
		@include tablet {
			margin-top: 88px;
		}
	}
	&-container {
		width: 100%;
		&__header {
			display: flex;
			justify-content: space-between;
			height: 32px;
			align-items: center;
			margin: 10px 15px 0;
			@include tablet {
				// margin-top: 64px;
				width: 50%;
				margin: 64px auto 0;
			}
			&-left, &-right {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}
		&__body {
			margin-top: 16px;
			.card-item {
				display: flex;
				justify-content: space-between;
				height: 32px;
    			align-items: center;
				width: 100%;
				.rank, .price {
					font-size: 16px;
				}

				.price {
					width: 50px;
					text-align: right;
				}
			}
			.card-item-header {
				font-weight: 700;
				font-size: 16px;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid rgba(0, 0, 0, 0.12);
			}
			.card-item-type {
				width: 100%;
				text-align: left;
			}

			.card-item-arrow {
				width: 20px;
				display: flex;
				justify-content: end;
			}
			.card-item-quantity {
				width: 15%;
				@include tablet {
					width: 70px;
				}
			}
			.card-item-total {
				width: 40%;
				text-align: right;
				@include tablet {
					width: 150px;
				}
			}
		}

		&__note {
			font-size: 12px;
			font-weight: 400;
			margin-top: 50px;
			@include tablet {
				font-size: 14px;
				margin-bottom: 60px;
			}
			.sub {
				margin-left: 20px;
			}
		}
	}
	&-button-export {
		background-color: black;
		color: white;
		font-size: 14px;
		font-weight: 400;
		width: 95px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		margin-top: 24px;
		@include tablet {
			font-size: 16px;
		}
	}
	@media (max-width: 768px) {
		.card {
			width: 100%;
		}
	}
}
.revenue_page-arrow {
	width: 12px;
	height: 12px;
}
.no-data {
	text-align: center;
	font-size: 16px;
	font-weight: 400;
	margin-top: 40px;

	@include tablet {
		font-size: 24px;
		margin-top: 73px;
	}
}