.gacha-carousel {
    width: 100%;
    height: auto;
    max-height: 540px;
    &__wrapper {
        margin: 0px;
    }

    &__item {
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;
        &-image {
            width: 100%;
            max-height: 540px;
            overflow: hidden;
            background-color: var(--color-lighter-gray);
        }
        img {
            width: 100%;
            height: auto;
            max-height: 540px;
            object-fit: cover;
            object-position: center;

            @include mobile-xxl {
                width: 100%;
                height: 180px;
            }
        }
    }
    &__dots {
        &.slick-dots {
            display: none !important;
        }
    }
    .slick-slider {
        width: 100%;
        margin: 0 auto;
        height: auto;
        @include desktop {
            width: 1170px;
        }
        @include mobile-xxl {
            width: 100%;
            height: 180px;
        }
    }
}
