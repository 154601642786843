.portal-live-ticket {
    &__wrapper {
        width: 100%;
        padding-top: 20px;
        // height: 250px;
        overflow: hidden;
    }
    &__item {
        @include tablet {
            width: 475px;
        }
        @include desktop {
            height: 230px;
        }
        .ant-col-sm-12{
            max-width: 100%;
        }
        &__container {
            // display: inline-block;
            margin: 20px 0;
            width: 100%;
            height: 100%;
        }
        &__image {
            &-container {
                overflow: hidden;
                width: 100%;
                height: 250px;
                position: relative;
                margin: 6px 0;
                background-color: var(--color-gray);
                border-radius: 10px;
                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: inline-block;
                    content: '';
                    box-shadow: inset 0px -50px 50px -20px var(--color-heavier-gray);
                }
            }
            &-image {
                // shadow
                object-fit: cover;
                height: 100%;
                width: 100%;
                object-position: center;
            }
        }
        &__info {
            position: absolute;
            bottom: 0;
            text-align: center;
            padding-bottom: 5px;
            width: 100%;
            z-index: 200;
            p {
                color: var(--color-white);
                font-size: 11px;
                line-height: 18px;
            }
            &-name  {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0 10px;
            }
        }
        &__label {
            text-align: center;
            width: 100%;
            a {
                color: var(--color-black);
                font-size: 16px;
                line-height: 18px;
                // width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0 10px;
                margin-top: 4px;
                text-decoration: underline;
            }
        }
    }
    &__no-carousel {
        display: flex;
        justify-content: center;
        &__container {
            display: flex;
            justify-content: start;
            flex-wrap: nowrap;
            .portal-live-ticket__item__container {
                margin-left: 8px;
            }
        }
    }
}