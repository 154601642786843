.portal-guide {
    &__list {
        &__container {
            .ant-collapse > .ant-collapse-item > .ant-collapse-header {
                font-size: 18px;
                font-weight: bold;
                line-height: 100%;
                color: var(--color-black);
                display: flex;
                &:hover {
                    color: var(--color-dark);
                }
                .ant-collapse-arrow {
                    svg {
                        color: var(--color-black);
                        font-size: 14px;
                    }
                }
            }
            .ant-collapse {
                &-item {
                    &-active {
                        .ant-collapse {
                            &-header {
                                color: var(--color-black) !important;
                                .ant-collapse-arrow {
                                    svg {
                                        fill: var(--color-dark);
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
                &-content-box {
                    margin-left: 22px;
                    padding-left: 35px;
                    border-left: 3px solid var(--color-black);
                    ul {
                        li {
                            margin-bottom: 8px;
                            span {
                                font-weight: bold;
                                color: var(--color-gray);
                                &:hover {
                                    color: var(--color-gray);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-detail {
        &__article {
            img {
                max-width: 100%;
            }
        }
        &__info {
            font-size: 14px;
            margin-top: 20px;
            &__date {
                margin-right: 10px;
            }
            &__category {
                font-size: 12px;
                line-height: 100%;
                color: var(--color-white);
                background-color: var(--color-primary);
                padding: 5px;
                border-radius: 10px;
                white-space: nowrap;
            }
        }
        &__link {
            margin-top: 50px;
        }
    }
}
