.gacha-collection {
    &__header {
        display: flex;
        justify-content: center;
        margin: 0px 0px 12px 0px;
        @include mobile-xxl {
            height: 50px;
        }
    }
    &__container {
        display: flex;
        justify-content: center;
    }
    &__list {
        width: 1170px;
        padding: 0 5px;

        &-empty {
            margin-bottom: 20px;
        }

        .ant-row{
            flex-flow: wrap;
        }
    }
    &__item {
        width: 100%;
        position: relative;
        &__wrap {
            position: relative;
            aspect-ratio: 1 / 1;
            max-height: 565px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                @include desktop {
                    width: 100%;
                    height: auto;
                    max-height: 565px;
                }
            }
        }
        &__layer {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.5);
            color: $color-white;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
            font-size: 32px;
            &__heading {
                font-size: 58px;
                font-weight: 900;
                @include mobile-xxl {
                    font-size: 32px;
                }
            }
            @include mobile-xxl {
                font-size: 16px;
            }
        }
    }
    &__pagination {
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
}
.gacha-collection-carousel {
    width: 100%;
    margin-bottom: 25px;
    padding-top: 20px;
    &__wrapper {
        margin: 0;
    }
    &__item {
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;
        @include mobile-xxl-fix {
            height: auto;
            display: flex;
            justify-content: center;
        }
        &-image {
            position: relative;
            width: 100%;
            overflow: hidden;
            background-color: var(--color-lighter-gray);
            aspect-ratio: 1 / 1;
            @include desktop {
                width: 100%;
                height: auto;
                max-height: 565px;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            @include desktop {
                width: 100%;
                height: auto;
                max-height: 565px;
            }
        }
        position: relative;
        &__wrap {
            position: relative;
        }
        &__layer {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.5);
            color: $color-white;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
            font-size: 32px;
            &__heading {
                font-size: 58px;
                font-weight: 900;
                @include mobile-xxl {
                    font-size: 32px;
                }
            }
            @include mobile-xxl {
                font-size: 16px;
            }
        }
    }
    &__dots {
        &.slick-dots {
            display: none !important;
        }
    }
    &__header {
        text-align: center;
        margin: 0px 0px 12px 0px;
        span {
            font-size: 40px;
            font-weight: bolder;
            text-transform: uppercase;
            color: var(--color-gray);
            @include mobile-xxl {
                font-size: 30px;
            }
        }
    }
}
.gacha-collection-carousel {
    .slick-slider {
        width: 100%;
        margin: 0 auto;
        height: auto;
        @include desktop {
            width: 1170px;
        }
        @include mobile-xxl-fix {
            width: 100%;
            height: auto;
        }
    }
    .slick-current {
        padding-right: 20px;
        @include mobile-xxl-fix {
            padding-right: 5px;
        }
    }
    .slick-slide {
        &:not(.slick-current) {
            padding-left: 20px !important;
            @include mobile-xxl-fix {
                padding-left: 5px !important;
            }
        }
    }
}
