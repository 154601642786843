.listFC {
    &__button {
        @include mobile {
            padding-top: 0px !important;
        }
        @include mobile-l {
            padding-top: 25px !important;
        }
        @include mobile-xxl {
            padding-top: 25px !important;
        }
        @include tablet {
            padding-top: 15px !important;
        }
        @include desktop {
            padding-top: 0px !important;
        }
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin-left: 15px;
        margin-top: 15px;
        margin-right: 15px;
        @include mobile {
            margin-left: 15px;
            margin-right: 15px;
        }
        &-item {
            width: 30%;
            @include mobile {
                width: 100%;
                display: flex;
                margin: 4px 0px;
            }
            @include mobile-l {
                width: 100%;
                display: flex;
                margin: 4px 0px;
            }
            @include mobile-xl {
                width: 50%;
                height: flex;
                margin: 4px 0px;
            }
            @include mobile-xxl {
                width: 50%;
                height: flex;
                margin: 4px 0px;
            }
            @include tablet {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
        }
    }
    &__image {
        width: 100%;
        @include mobile {
            width: 50%;
            height: auto;
            max-height: 120px;
        }
        @include mobile-l {
            width: 50%;
            height: auto;
            max-height: 120px;
        }
        @include mobile-xl {
            width: 50%;
            height: auto;
            max-height: 120px;
        }
        @include mobile-xxl {
            width: 50%;
            height: auto;
            max-height: 120px;
        }
        @include tablet {
            width: 100%;
            height: auto;
            flex-direction: column;
            max-height: 230px;
        }
        .listFC-image-club {
            @include desktop {
                height: 225px !important;
            }
            @include mobile {
                height: 100px !important;
            }
        }
    }
    &__link {
        color: $color-black;
        text-decoration: underline;
        margin: 10px 15px 25px;
        a {
            cursor: pointer;
            font-size: 16px;
        }
        @include mobile {
            width: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            // align-items: center;
        }
        @include tablet {
            margin-left: 2px;
        }
        @include desktop {
            margin-left: 2px;
        }
    }
}

.btn {
    margin-left: 15px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    @include mobile {
        padding: 0 auto;
        margin-top: 35px;
        margin-bottom: 10px;
        font-size: 16px;
    }
    @include desktop {
        margin-top: 95px;
    }

    @media (max-width: 1089px) and (min-width: 768px) {
        margin-top: 125px;
    }

    @media (max-width: 768px) and (min-width: 620px) {
        margin-top: 35px;
    }

    border: 1px solid $color-black;
    background-color: $color-white;
    color: $color-black;
    &-joined {
        padding: 6px 20px;
        border-radius: 50px;
        @include mobile {
            border-radius: 28px;
            padding: 5px 20px;
        }
    }
    &-favourite {
        padding: 6px 20px;
        border-radius: 50px;
        @include mobile {
            border-radius: 28px;
            padding: 5px 20px;
        }
    }
    &:hover {
        background-color: $color-black;
        color: $color-white;
    }
    &-true {
        background-color: $color-black;
        color: $color-white;
    }
    &-false {
        background-color: $color-white;
        color: $color-black;
    }
}

.item-listFC {
    margin-left: 0px !important;
    margin-top: 15px !important;
    margin-right: 0px !important;
    flex-wrap: wrap;
    @include mobile-xl {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
    @include tablet {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
    @include desktop {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
}

.padding-item-fc {
    padding-left: 0px !important;
}
