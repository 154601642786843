.portal-artist-goods {
    &__wrapper {
        margin: 0 20px;
    }
    &__item {
        &__container {
            width: 110px;
            height: 145px;
            margin-bottom: 15px;
        }
        &__image {
            &-container {
                display: inline-block;
                overflow: hidden;
                position: relative;
                margin-bottom: 15px;
                background-color: $color-portal-gray;
                border-radius: 10px;
                background-color: $color-portal-gray;
                width: 100%;
                height: 100%;
                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: inline-block;
                    content: '';
                    box-shadow: inset 0px -50px 50px -20px
                        $color-portal-heavier-gray;
                }
            }
            &-image {
                object-fit: cover;
                height: 100%;
                width: 100%;
                object-position: center;
            }
        }
        &__info {
            position: absolute;
            bottom: 0;
            text-align: center;
            padding-bottom: 5px;
            width: 100%;
            z-index: 200;
            p {
                color: $color-white;
                font-size: 11px;
                line-height: 18px;
            }
        }
    }
}
