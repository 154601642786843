.portal-artist {
    background-color: white;
    min-height: 100vh;
    padding-top: 100px;
    @include mobile-xxl {
        padding-top: 65px;
    }
    padding-bottom: 10px;

    @media (max-width: 1200px) and (min-width: 768px) {
        margin-left: 15px;
        margin-right: 15px;
    }

    &__header {
        padding: 0 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__top {
            margin-bottom: 15px;
            &-link {
                color: var(--color-primary);
                font-size: 16px;
                &:hover,
                &:focus,
                &:active {
                    color: var(--color-primary);
                }
                .anticon {
                    vertical-align: 0.125em;
                }
            }
        }
        &__nav {
            margin-bottom: 15px;
            width: 100%;
            flex-shrink: 1;
            overflow: hidden;
            
            &__item {
                padding: 10px 0;
                width: max-content;
                .nav__item {
                    color: var(--color-black);
                    border: none;
                    background-color: transparent;
                    box-shadow: none;
                    font-size: 22px;
                    @include mobile {
                        font-size: 16px !important;
                        padding: 5px 16px !important;
                    }
                    @include mobile-xxl {
                        font-size: 16px !important;
                        padding: 5px 16px !important;
                    }
                    padding: 8px 20px;
                    height: 32px;
                    border-radius: 50px;
                    margin-right: 6px;
                    transition: all 0.25s ease-in;
                    border: 1px solid var(--color-black);
                    font-family: 'Times New Roman', Times, serif;
                    margin-left: 6px;
                    &:hover,
                    &:focus,
                    &:active {
                        color: rgba($color: #000000, $alpha: 0.6);
                        border: 1px solid rgba($color: #000000, $alpha: 0.7);
                        background-color: var(--color-white);
                    }
                }
                &.active {
                    .nav__item {
                        color: var(--color-white);
                        background-color: var(--color-black);
                    }
                }
            }

            &__item--ranking {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 6px 20px;
                border-radius: 50px;
                margin: 2px 4px 0;
                font-family: 'Times New Roman', Times, serif;
                color: #fff;
                font-size: 22px;
                @include mobile-xxl {
                    font-size: 16px ;
                    padding: 5px 16px ;
                }
                &.enable {
                    background-color: #000000;
                    cursor: pointer;
                }
                &.disable {
                    cursor: no-drop;
                    background-color: #989696;
                }
            }

            .slick-track {
                display: flex;
            }
        }
    }
    &__resume {
        max-width: 1170px;
        margin: 0 auto;
        margin-top: 20px;
        @include mobile {
            padding: 15px;
        }
        &__info {
            @include tablet {
                padding-left: 15px;
                padding-right: 0px;
                padding-bottom: 15px;
            }
            @include desktop {
                padding-left: 15px;
                padding-right: 0px;
                padding-bottom: 15px;
            }
        }
    }
    &__content {
        &__container {
        }
        &__section {
            .anticon {
                vertical-align: 0.125em;
            }
            &__container {
                font-size: 26px;
                font-weight: 700;
                text-align: start;
                font-family: 'Times New Roman', Times, serif;
                color: var(--color-black);
            }
            &__content {
                margin-top: 15px;
                font-size: 18px;
                font-weight: 500;
                word-wrap: break-word;
            }
            &__header-link {
                font-size: 16px;
                font-weight: 500;
                word-wrap: break-word;
            }
        }
        &__link {
            font-size: 14px;
            color: var(--color-dark);
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
                'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
                'Helvetica Neue', sans-serif;
            font-weight: 400;
            &:hover {
                color: var(--color-primary);
            }
        }
    }
    &__liveLink {
        &__wrapper {
            display: flex;
            justify-content: center;
            margin-top: 10px;
        }
    }
}

.portal-artists__list__flex {
    .ant-row {
        flex-wrap: wrap;
    }
}

.artist-video-list {
    &__item {
        padding: 15px 0;
        border-top: 0.25px dotted var(--color-light-gray);
        &__title {
            white-space: nowrap;
            font-size: 12px;
        }
    }
}

.artist-snsLinks {
    &__container {
        margin: 40px 25px 40px;
        @include mobile {
            margin: 15px 25px 20px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__item {
        margin-right: 15px;
    }
    &__logo {
        width: 50px;
        height: 50px;
        @include desktop {
            width: 35px;
            height: 35px;
        }
    }
}

.artist-live-ticket {
    width: 100%;
    &__wrapper {
        margin: 0 40px;
        margin-bottom: 20px;
    }
    &__item {
        width: 100%;
        &__container {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-top: 10px;
        }
        &__divider {
            color: var(--color-heavier-gray);
            height: 2rem;
            margin: 0;
        }
        &__info {
            &-container {
                max-width: 55%;
                color: var(--color-gray);
                line-height: 22px;
            }
            &-bigger {
                p {
                    font-size: 16px;
                }
            }
            &-smaller {
                text-align: left;
                span:nth-of-type(1) {
                    margin-right: 15px;
                }
                span {
                    font-size: 14px;
                }
            }
        }
    }
}

.artist-divider {
    margin-bottom: 15px;
}

// .portal-artist-artist {
//     background-color: white;
// }

.artist-member-rank-detail {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100vw;

    &__rank {
        flex-direction: column;
    }

    &__desc {
        width: 45vw;
        margin: 15px auto;
        white-space: pre-wrap;
        // line-height: 12px;
        color: black;

        @include mobile {
            width: 90vw;
        }
    }

    &__desc_fix {
        width: 45vw;
        // background-color: red;
        // line-height: px;
        white-space: pre-wrap;

        @include mobile {
            width: 90vw;
        }
    }

    @include tablet {
        width: 50vw;
        margin: 20px auto;
        flex-direction: row;

        &__rank {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__desc {
            width: 20%;
            margin: 15px 0;
        }
    }
}

.artist-detail-fc-option {
    display: flex;
    // gap: 20px;
    flex-direction: column;
    width: 100vw;
    justify-content: space-between;
    .artist-item-fc {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }

    @include tablet {
        flex-wrap: wrap;
        flex-direction: row;
        width: 50vw;
        margin: 0 auto;

        .artist-item-fc {
            width: 35%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }
    }
}

.name-shop-artist {
    font-size: 16px;
    text-decoration: underline;
}

.row-shop-artist {
    flex-wrap: wrap;
}

.img-shop-artist {
    @include mobile {
        height: 120px;
    }
    @include mobile-xl {
        height: 70px;
    }
    @include tablet {
        height: 120px;
    }
    @include desktop {
        height: 205px;
    }
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
}

.artist-profile-introduction {
    padding: 0 15px 15px 0;
}

.portal-live-ticket-artist {
    display: flex;
    width: 459px;

    @include mobile {
        width: 100%;
    }
    @include mobile-xl {
        width: 100%;
    }

    .portal-live-ticket-artist-img-wrapper {
        overflow: hidden;
        height: 140px;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        @include desktop {
            height: 160px;
        }
        img {
            height: 100%;
            object-fit: cover;
            background-repeat: no-repeat;
        }
    }
}

.portal-live-ticket-artist-info {
    width: 50%;
    font-size: 18px;
    padding: 15px;
    text-align: start;
    background-color: var(--color-black);
    p {
        color: var(--color-white);
    }
}

.createList-end {
    text-align: end;
    margin-top: 0px;
    @include desktop {
        font-size: 18px;
    }
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
}

.artist-live-talk-list-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.width-skeleton-loading {
    @include desktop {
        width: 1170px;
        margin: 0 auto;
    }
    margin-bottom: 50px;
}

.padding-skeleton-mobile {
    @include mobile {
        padding-left: 15px;
        padding-right: 15px;
    }
}
