.portal-blog-detail {
    &__link {
        margin-top: 50px;
    }

    &__btn {
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }

    &__article {
        img {
            max-width: 100%;
        }
    }
}

.portal-blog-detail-comments {
    margin-top: 20px;
    margin-bottom: 20px;

    &__title {
        font-weight: 900;
        font-size: 18px;
        margin-bottom: 10px;
    }
}

.portal-blog-detail-comment-detail {
    margin-bottom: 30px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        &--item {
            display: flex;
            gap: 10px;
            align-items: center;
            vertical-align: bottom;
        }

        &--avatar {
            width: 30px;
            height: 30px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
                object-fit: cover;
            }
        }

        &--user {
            font-weight: 800;
            font-size: 16px;
            vertical-align: bottom;
            display: inline-block;
            line-height: 1;
        }

        &--comment-time {
            font-size: 10px;
            vertical-align: bottom;
            display: inline-block;
        }
    }

    &__content {
        font-size: 14px;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        text-decoration: underline;
        margin-top: 3px;
    }

    &__reply-input-container {
        display: flex;
        gap: 10px;
        align-items: flex-end;

        &--input {
            flex: 1;


            textarea {
                border-radius: 5px;
                border: 1px solid #979797;
            }
        }

        &--submit-btn {
          &:hover {
            cursor: pointer;
          }
        }

        &--submit-btn-disabled {
          &:hover {
            cursor: not-allowed;
          }
        }
    }

    &__reply-input-wrapper {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;

        >div {
            width: 100%;
            padding-left: 25px;
        }
    }

    &__list-reply {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;

        >div {
            width: 100%;
            padding-left: 25px;
        }
    }
}

.ant-image-preview {
    &-img-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
