.portal-ranking {
    max-width: 1200px;
    margin: 150px auto 90px;
    text-align: center;
    min-height: 300px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    @include mobile-xxl {
        padding: 0 10px;
        margin: 90px auto 20px;
    }

    &__title {
        display: flex;
        align-items: center;
        background-color: #000;
        color: #fff;
        padding: 8px 10px 8px 15px;
        border-radius: 70px;
        @include mobile-xxl {
            justify-content: space-between;
            padding: 10px 15px;
        }
        p {
            font-size: 24px;
            margin-right: 20px;
            @include mobile-xxl {
                font-size: 18px;
            }
        }
        img {
            @include mobile-xxl {
                width: 28px;
                height: auto;
            }
        }
    }

    &__search {
        margin-top: 20px;
        text-align: right;
		@include mobile-xxl {
			margin-top: 10px;
		}
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            padding-right: 20px;
            text-align: left;
        }
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
            .ant-select-selector {
            border-color: #000;
            box-shadow: none;
        }
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
            border-color: #000;
            border-radius: 5px;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border-color: #000;
            border-radius: 5px;
        }
    }

    &__content {
        margin-top: 20px;
        min-height: 100px;
        @include mobile-xxl {
            min-height: 100px;
        }
        &__modal {
            display: flex;
            border: 1px solid #d2d2d2;
            border-radius: 10px;
            padding: 10px 20px;
            margin: 10px 0;
            min-height: 160px;
            @include mobile-xxl {
                justify-content: space-between;
                padding: 10px 5px;
                min-height: 180px;
            }
            &__avatar {
                min-width: 150px;
                max-width: 160px;
                text-align: center;
                height: auto;
                position: relative;
                @include mobile-xxl {
                    width: 170px;
                }
                &__award {
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    width: 40px;
                }
                &__user--avatar {
                    max-width: 160px;
                    height: auto;
                }
            }
            &__center {
                margin: 0 50px;
                text-align: left;
                max-width: 30%;
                min-width: 30%;
                @include mobile-xxl {
                    margin: 0 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    min-width: 45%;
                }
                &__name {
                    font-weight: 700;
                    color: #000;
                    font-size: 25px;
                    @include mobile-xxl {
                        display: none;
                    }
                }
                &__name--sp {
                    display: none;
                    @include mobile-xxl {
                        display: block;
                        font-weight: 700;
                        color: #000;
                        font-size: 20px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                &__point--sp {
                    display: none;
                    @include mobile-xxl {
                        border: 2px solid #ff0000;
                        border-radius: 10px;
                        padding: 0 5px;
                        font-size: 20px;
                        color: #ff0000;
                        font-weight: 700;
                        margin: 3px 0;
                        width: 150px;
                        height: 70px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        p {
                            text-align: center;
                        }
                    }
                }
                &__button {
                    background-color: #000;
                    color: #fff;
                    padding: 10px;
                    margin-top: 45px;
                    font-size: 14px;
                    border-radius: 5px;
                    @include mobile-xxl {
                        margin-top: 0;
                        font-size: 13px;
                    }
                }
            }
            &__right {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 55%;
                margin: 0 auto;
                border-left: 1px dotted #D2D2D2;
                @include mobile-xxl {
                    display: none;
                }
                &__button {
                    border: 2px solid #ff0000;
                    padding: 20px 0;
                    border-radius: 70px;
                    text-align: center;
                    color: #ff0000;
                    font-weight: 700;
                    width: 70%;
                }
                .low {
                    font-size: 30px;
                }
                .medium {
                    font-size: 20px;
                }
                .height {
                    font-size: 15px;
                }
            }
        }
    }

    &__back {
        padding: 25px 0;
        &__button {
            background-color: #000;
            color: #fff;
            padding: 12px 90px;
            border-radius: 6px;
            @include mobile-xxl {
                padding: 12px 50px;
            }
        }
    }

	&__info {
		display: flex;
		align-items: center;
		border: 1px solid #d2d2d2;
		border-radius: 10px;
		padding: 10px 20px;
		margin: 10px 0 30px;
		@include mobile-xxl {
			margin: 10px 0;
		}
		&__avatar {
			width: 200px;
			height: auto;
			cursor: pointer;
			@include mobile-xxl {
				width: 150px;
				height: auto;
			}
		}
		&__name {
			color: #000;
			font-weight: 700;
			margin-left: 20px;
			font-size: 25px;
			@include mobile-xxl {
				font-size: 20px;
			}
		}
	}

	&__fan--content {
        min-height: 100px;
		&__modal {
			display: flex;
			align-items: center;
			border: 1px solid #d2d2d2;
			border-radius: 10px;
			padding: 10px 20px;
			margin: 10px 0;
			min-height: 138px;
			@include mobile-xxl {
				padding: 10px 15px;
				justify-content: space-between;
			}
			&__avatar {
				width: 100px;
				@include mobile-xxl {
					width: 55px;
				}
			}
			&__name {
				font-size: 23px;
				font-weight: 700;
				color: #000;
				width: 200px;
				text-align: center;
				@include mobile-xxl {
					font-size: 17px;
					width: fit-content;
				}
			}
			&__point {
				color: #FF0000;
				font-size: 23px;
				font-weight: 700;
				margin-left: 30px;
				@include mobile-xxl {
					font-size: 20px;
					margin-left: 0;
				}
			}
		}
	}
    &__page--not--found {
        font-size: 25px;
        height: 150px;
    }
    .low {
        font-size: 40px;
    }
    .medium {
        font-size: 30px;
    }
    .height {
        font-size: 25px;
    }
}
