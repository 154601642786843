.extension {
    &:hover {
        background-color: rgba(120, 120, 120, 1);

    }
}

.extension-active {
    background-color: rgba(120, 120, 120, 1);
}

.creator-content {
    .iframe-wrapper {
        max-width: 100%;
    }

    h1 {
        font-size: 2.42916rem;
        font-weight: bold;
    }

    h2 {
        font-size: 2.125rem;
        font-weight: 500;
    }

    h3 {
        font-size: 1.5rem;
        font-weight: 500;
    }

    h4 {
        font-size: 1.25rem;
        font-weight: 500;
    }

    h5 {
        font-size: 1rem;
        font-weight: 500;
    }

    h6 {
        font-size: 0.875rem;
        font-weight: 500;
    }

    p {
        font-size: 1em;
        color: black;
    }

    ul {
        list-style-type: disc;
        padding-left: 40px;
        margin: 1em 0;
    }

    ol {
        list-style-type: decimal;
        padding-left: 40px;
        margin: 1em 0;
    }

    li {
        margin: 0.5em 0;
    }

    a {
        color: #0000EE !important;
    }

    blockquote {
        padding: 10px 20px;
        margin: 20px 0;
        border-left: 5px solid #ccc;
        background-color: #f9f9f9;
        font-style: italic;
        color: #555;
        position: relative;
    }

    blockquote::before {
        background: transparent !important;
    }

    blockquote p {
        margin: 0;
    }

}