.portal-setting-creator {
    background-color: white;
    min-height: 100vh;
    padding-top: 150px;
    max-width: 1170px; // max width of menu bar
    margin: auto;

    &__general {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;

        &-background,
        &-color {
            background: #000000;
            color: #FFFFFF;
            padding: 10px 20px;
            text-align: center;
            font-size: 16px;

            &:hover {
                opacity: 0.7;
            }
        }

    }

    &__submit {
        margin-top: 50px;
        text-align: center;

        &-btn {
            background: #000000;
            color: #FFFFFF;
            padding: 10px 140px;
            text-align: center;
            font-size: 16px;

            &:hover {
                opacity: 0.7;
            }

            @include mobile-xxl {
                padding: 10px 40px;
            }
        }
    }

    @include max1200 {
        padding: 150px 20px 0 20px;
    }

    @include desktop {
        &__general {
            justify-content: end;

            &-color {
                margin-left: 15px;
            }
        }
    }

    @include mobile-xxl {
        padding-top: 80px;
    }

    &__toolbar {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &--back-button {
            color: black;
            display: flex;
            align-items: center;
        }
    }

    &__preview-area {
        position: relative;
    }

    &__setting-header {
        position: relative;
    }

    &__edit-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 91;
    }
}