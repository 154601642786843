.portal-carousel {
  width: 100%;
  
  &__wrapper {
    overflow: hidden;
    &__carousel {
      position: relative;
      width: 100vw;
      max-width: 1170px;
      max-height: 600px;
      margin: 0 auto;
      &__chevron {
        position: absolute;
        bottom: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: var(--color-white);
        &__prev {
          @include mobile-xxl {
            width: 20px;
            margin-left: 10px;
          }
          @include tablet {
            width: 40px;
            margin-left: 20px;
          }
        }
        &__next {
          @include mobile-xxl {
            width: 20px;
            margin-right: 10px;
          }
          @include tablet {
            width: 40px;
            margin-right: 20px;
          }
        }
      }
    }

    &__carousel-pick-up {
      position: relative;
      width: 100vw;
      max-width: 1170px;
      margin: 0 auto;

      &__chevron {
        position: absolute;
        bottom: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: var(--color-white);
        &__prev {
          @include mobile-xxl {
            width: 20px;
            margin-left: 10px;
          }
          @include tablet {
            width: 40px;
            margin-left: 20px;
          }
        }
        &__next {
          @include mobile-xxl {
            width: 20px;
            margin-right: 10px;
          }
          @include tablet {
            width: 40px;
            margin-right: 20px;
          }
        }
      }
    }
  }

  &__wrapper_creator {
    overflow: unset;
  }

  &__item {
    position: relative;
    width: 100%;
    height: 100%;
    // @include mobile-xxl {
    //   height: 180px;
    // }
    overflow: hidden;

    &__badge {
      position: absolute;
      top: 50px;
      left: 20px;
      color: var(--color-white);
      background: var(--color-accent);
      padding: 5px 10px;
      line-height: 1;
      text-transform: uppercase;
      border-radius: 12px;
      height: 24px;
      background: linear-gradient(90deg, var(--color-highlight) 0%, var(--color-accent) 100%);
    }

    &-image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: var(--color-lighter-gray);

      img {
        width: 100%;
        height: 100%;
        min-height: 180px;
        max-height: 600px;
        object-fit: contain;
      }
    }

    &-image-group-page {
      margin: 0 10px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: var(--color-lighter-gray);
      
      @include tablet {
        // max-height: 200px;
      }
      
      img {
        width: 100%;
        height: 100%;
        min-height: 180px;
        object-fit: cover;
        object-position: center; 

        // @include tablet {
        //   max-height: 200px;
        // }
      }
    }
  }

  &__dots {
    &.slick-dots {
      @include mobile-xxl {
        bottom: -20px;
      }
      @include tablet {
        bottom: -40px;
      }

      li {
        @include mobile-xxl {
          width: 10px;
          max-width: 10px;
          height: 10px;
        }
        @include tablet {
          width: 20px;
          max-width: 20px;
          height: 20px;
        }
        color: var(--color-white);
        background-color: var(--color-white);
        border-radius: 100%;
        border: 1px solid var(--color-heavy-gray);

        button {
          @include mobile-xxl {
            width: 10px;
            max-width: 10px;
            height: 10px;
          }
          @include tablet {
            width: 20px;
            max-width: 20px;
            height: 20px;
          }
          border-radius: 100%;
        }

        &.slick-active {
          @include mobile-xxl {
            width: 10px;
            max-width: 10px;
            height: 10px;
          }
          @include tablet {
            width: 20px;
            max-width: 20px;
            height: 20px;
          }
          border-radius: 100%;
          background-color: var(--color-heavy-gray);

          button {
            @include mobile-xxl {
              width: 10px;
              max-width: 10px;
              height: 10px;
            }
            @include tablet{
              width: 20px;
              max-width: 20px;
              height: 20px;
            }
            border-radius: 100%;
            background-color: var(--color-heavy-gray);
          }
        }
      }
    }
  }
}

.pick-up-carousel-wrapper {
  width: 100%;
  // background-color: red;
  @include tablet {
    margin-left: 15px;
    margin-right: 15px;
  }
}