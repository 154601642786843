@import 'gacha-carousel';
@import 'gacha-collection';

.gacha-home {
    margin-top: 117px;
    padding-bottom: 20px;
    @include mobile-xxl {
        margin-top: 66px;
    }
    &__actions {
        margin: 20px 20px;
    }
}
