.portal-ec-mall {
    @include mobile {
        padding-left: 15px;
        padding-right: 15px;
    }
    p {
        color: var(--color-heavier-gray);
    }
    &__wrapper {
        @include mobile-xxl {
            margin-bottom: 30px;
            padding-top: 10px;
        }
        @include tablet {
            margin-bottom: 60px;
            padding-top: 30px;
        }
    }
    &__heading {
        margin-bottom: 15px;
        font-size: 15px;
        @include mobile-xxl {
            // margin: 0 20px;
            margin-bottom: 15px;
            font-size: 15px;
        }
        @include tablet {
            // margin: 0 20px;
            margin-bottom: 40px;
        }
    }
    &__row {
        text-align: center;
        padding: 0px 15px;

        @include mobile {
            padding: 0px;
        }

        &__content {
            margin: 0px 10px;
            &__img{
                @include mobile-xxl {
                    .portal-ec-mall__row__content__img_mobile {
                        display: block;
                    }
                    .portal-ec-mall__row__content__img_PC {
                        display: none;
                    }
                }

                @include tablet {
                    .portal-ec-mall__row__content__img_mobile {
                        display: none;
                    }
                    .portal-ec-mall__row__content__img_PC {
                        display: block;
                        width: 100%;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &__content-personal-talk-top {
            &__img{
                @include mobile-xxl {
                    .portal-ec-mall__row__content__img_mobile {
                        display: block;
                    }
                    .portal-ec-mall__row__content__img_PC {
                        display: none;
                    }
                }

                @include tablet {
                    .portal-ec-mall__row__content__img_mobile {
                        display: none;
                    }
                    .portal-ec-mall__row__content__img_PC {
                        display: block;
                        width: 100%;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &__link {
        text-align: right;
        text-decoration: underline;
        font-weight: bold;
        padding-top: 25px;
        margin: 0 20px;
        @include mobile-xxl {
            padding-right: 6px;
            font-size: 11px;
        }
        @include tablet {
            padding-right: 16px;
            font-size: 19px;
            letter-spacing: 1.5px;
        }
    }
    &__button {
        width: 100%;
        &__container {
            padding-top: 15px;
        }
        &__btn {
            font-size: 10px;
            &:hover,
            &:focus,
            &:active {
                color: var(--color-white);
                background-color: var(--color-primary);
            }
        }
    }
}


.portal-ec-mall-artist {
    text-align: start;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 700;
    font-size: 26px;
    background-color: var(--color-white);
    p {
        color: var(--color-heavier-gray);
    }
    &__wrapper {
        @include mobile-xxl {
            margin-bottom: 30px;
            padding-top: 10px;
        }
        @include tablet {
            margin-bottom: 60px;
            padding-top: 30px;
        }
    }
    &__heading {
        margin-bottom: 15px;
        font-size: 15px;
        @include mobile-xxl {
            // margin: 0 20px;
            margin-bottom: 15px;
            font-size: 22px;
        }
        @include tablet {
            // margin: 0 20px;
            margin-bottom: 40px;
        }
    }
    &__row {
        text-align: center;
        // padding: 0px 8px;
        &__content {
            margin: 0px 10px;
            &__img{
                @include mobile-xxl {
                    .portal-ec-mall__row__content__img_mobile {
                        display: block;
                    }
                    .portal-ec-mall__row__content__img_PC {
                        display: none;
                    }
                }

                @include tablet {
                    .portal-ec-mall__row__content__img_mobile {
                        display: none;
                    }
                    .portal-ec-mall__row__content__img_PC {
                        display: block;
                        width: 100%;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &__link {
        text-align: right;
        text-decoration: underline;
        font-weight: bold;
        padding-top: 25px;
        margin: 0 20px;
        @include mobile-xxl {
            padding-right: 6px;
            font-size: 12px;
        }
        @include tablet {
            padding-right: 16px;
            font-size: 19px;
            letter-spacing: 1.5px;
        }
    }
    &__button {
        width: 100%;
        &__container {
            padding-top: 15px;
        }
        &__btn {
            font-size: 10px;
            &:hover,
            &:focus,
            &:active {
                color: var(--color-white);
                background-color: var(--color-primary);
            }
        }
    }
}
