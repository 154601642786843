.base-modal {
    &__setting-creator-modal {
        max-width: 800px !important;
        width: 100% !important;
        padding: 0 10px;

        .ant-modal-header {
            text-align: center;
            border-bottom: none;
        }

        .ant-modal-title {
            font-size: 20px;
            font-weight: bold;
        }

        .ant-modal-close-x {
            width: 45px;
            height: 45px;
            line-height: 45px;
        }

        .ant-modal-footer {
            text-align: center;
            border-top: none;
        }
    }

    &__general-setting-modal {
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
        padding: 0 50px;

        .input-switch-group {
            width: 100%;
            margin: 20px 0;
            justify-content: space-between;
            display: flex;

            &__label {
                max-width: calc(100% - 100px);
            }

            &__switch {
                display: flex;

                &_icon {
                    width: 35px;
                }

                &_label {
                    margin-left: 20px;
                    width: fit-content;
                }
            }
        }
    }

    .ant-btn-primary {
        background-color: black;
        margin-left: 0px !important;
        border: none;
    }
}

.modal-upload-media {
    .ant-modal-title {
        font-weight: bold;
    }
}

.modal-setting-color {
    &__setting {
        display: flex;
        justify-content: center;
    }

    .d-title-setting-entire-page-text {
        margin-bottom: 10px;
    }
    .fit-content {
        width: fit-content;
    }
    .margin-center {
        margin: 0 auto 30px;
    }

    .box-picker {
        margin-bottom: 30px;
    }

    .d-title-setting-footer-text {
        margin: 0 0 20px;
    }
}

.modal-setting-header {
    &__setting-visibility {
        text-align: center;
    }

    &__setting-color {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &--item {
            margin-top: 15px;
        }

        &--label {
            margin-bottom: 10px;
        }
    }
}

.box-setting-footer-modal {
    @include tablet {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }
}

@include desktop {
    .base-modal {
        &__general-setting-modal {
            padding: 0;

            .input-switch-group {
                width: 40%;

                &__switch {
                    &_icon {
                        width: 41px;
                    }
                }
            }
        }
    }

    .modal-setting-color {
        .ant-modal-body {
            // justify-content: space-between;

            .d-title-setting-footer-text {
                margin-top: 0;
            }
        }
    }
}

.modal-setting-one-color {
    .ant-modal-body {
        display: flex;
        justify-content: center !important;
    }
}
.d-error {
    color: #f05454;
}

@include mobile-xxl {
    .modal-setting-header {
        &__setting-color {
            flex-wrap: wrap;

            &--item {
                width: 100%;
                text-align: center;

                .sketch-picker {
                    margin: auto;
                }
            }

            &--label {
                margin-bottom: 10px;
            }
        }
    }
}