.fixed-section {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.util {
    &__youtube-embedded {
        max-width: 100%;
        width: 100%;
        margin: 0;
        line-height: 1;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border: 0;
        background-color: #000;
    }
}

.text {
    &-center {
        text-align: center;
    }

    &-left {
        text-align: left;
    }

    &-right {
        text-align: right;
    }

    &-small {
        font-size: 0.789rem;
    }

    &-normal {
        font-size: 1rem;
    }

    &-large {
        font-size: 1.615rem;
        @include desktop {
            font-size: 2.368rem;
        }
        @include desktop-4k {
            font-size: 2.087rem;
        }
        @include laptop-s {
            font-size: 2rem;
        }
    }
}

.on-air {
    font-size: 9px;
    text-transform: uppercase;
    font-weight: bolder;
    padding: 2px 4px;
    line-height: 1;
    color: var(--color-gray);
    border-radius: calc(13px / 2);

    &.active {
        color: var(--color-secondary);
    }

    &:hover,
    &:active {
        font-size: 9px;
        text-transform: uppercase;
        color: var(--color-gray);

        &.active {
            color: var(--color-secondary);
        }
    }
}

.portal-heading {
    padding: 4px;
    padding-left: 15px;
    background-color: var(--color-black);
    border-radius: 70px;
    p {
        color: var(--color-white);
        font-size: 16px;
        line-height: 21px;
        font-weight: 700;
        @include tablet {
            font-size: 26px;
            line-height: 28px;
            margin-left: 8px;
        }
    }
    @include tablet {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.btn-font-size-fc-artist {
    font-size: 20px;
}
