.portal-creator {
    background-color: white;
    min-height: 100vh;
    max-width: 1170px; // max width of menu bar
    margin: auto;

    &__content {
        padding: 40px 20px 0 20px;
    }

    @include max1200 {
        // padding: 150px 20px 0 20px;
    }

    @include mobile-xxl {
        padding-top: 67px;
    }

    &__banner {
        position: relative;

        &_img {
            aspect-ratio: 16 / 9;
            width: 100%;
        }
    }


    &__gallery {
        position: relative;
    }

    &__favorite {
        &-btn {
            height: 32px;
            color: var(--color-white);
            background-color: #f05454;
            font-size: 18px;
            width: 220px;
            text-align: center;
            line-height: 1;
            transition: all .25s ease-in;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mobile-xxl {
                margin: auto;
            }
        }
    }

    &__live-button-container {
        @include mobile-xxl {
            margin: auto;
        }
    }

    &__rank {
        text-align: center;
        font-weight: 700;
        width: 100%;
        margin: auto;

        @include mobile-xxl {
            width: 100%;
        }

        &-title {
            font-size: 25px;

            @include tablet {
                font-size: 36px;
            }
        }

        &-sub-title {
            font-weight: 700;
            font-size: 20px;

            @include tablet {
                font-size: 24px;
            }
        }

        &-btn-join {
            display: flex;
            // background: #F05454;
            border-radius: 20px;
            padding: 4px 0 2px;
            // color: white;
            font-size: 15px;
            font-weight: 700;
            margin: 15px auto 25px;
            justify-content: center;
            position: relative;

            &:hover,
            &:active,
            &:focus {
                color: white;
            }

            img {
                padding: 4px 0 2px 0px;
                position: absolute;
                right: 8px;
            }
        }

        &-box {
            @include tablet {
                display: flex;
                justify-content: center;
            }

            &-movie,
            &-photo {
                button {
                    padding: 10px 50px;
                    // background: gray;
                    border-radius: 25px;
                    font-size: 20px;
                    margin: 10px 0 30px;
                    color: #FFFFFF;

                    @include tablet {}
                }
            }
        }

        &--description {
            text-align: left;
            padding-left: 50px;
            width: 100%;
            word-break: break-all;
            height: 200px;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
                background: currentColor;
            }

            @include mobile-xxl {
                text-align: center;
                padding-left: 0;
            }
        }

        &--info {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .cursor-not-allow {
        cursor: not-allowed
    }

    .portal-carousel {
        &__wrapper {
            &__carousel {
                width: 100%;
            }

            .slick-dots {
                flex-wrap: wrap;
                position: unset;
                margin-top: 15px;
                height: auto;
            }
        }

        .slick-slider {
            .slick-list {
                width: 100%;

                .slick-track {
                    .slick-slide {
                        height: fit-content;
                    }
                }
            }
        }

        &__item {
            &-image-group-page {
                margin: 0;
                background-color: #979797;
                margin: 0 auto;

                img,
                .box-video {
                    width: 100%;
                    height: auto;
                    min-height: unset;
                    margin: 0 auto;
                    aspect-ratio: 4 / 3;
                    border-radius: unset;

                    video {
                        height: 100%;
                    }
                }

                .box-video {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    &__profile {
        * {
            max-width: 100%;
        }

        color: initial;

        td,
        th {
            border: 1px solid #ccc;
        }

        p:empty {
            min-height: 22.5px;
        }
    }

    &__basic {
        display: flex;
        gap: 50px;
        margin-bottom: 20px;

        &--div-set-link {
            display: flex;
            flex-wrap: nowrap;
        }

        &--info-setlink-input-l {
            flex: 1;
            margin-right: 20px;
            margin-bottom: 10px;
        }

        &--info-setlink-input-r {
            flex: 1;
            margin-bottom: 10px;
        }

        &--bin-icon {
            width: 22px;
            height: 25px;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }

        &--chain-icon {
            width: 22px;
            height: 25px;
            margin-right: 10px;
        }

        &--a-link {
            color: var(--color-link);
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--list-link {
            display: flex;
            flex-wrap: nowrap;
        }

        &--plus-div {
            margin-top: 30px;
            text-align: center;
        }

        &--plus-icon {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 20px;
            height: 20px;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }

        &--avatar-container {
            position: relative;
            width: 180px;
            height: 180px;

            @include mobile-xxl {
                margin: auto;
            }

            @include tablet {
                width: 200px;
                height: 200px;
            }

            @include desktop {
                width: 300px;
                height: 300px;
            }
        }

        &--avatar-edit-btn {
            position: absolute;
            right: 10px;
            top: 10px;
        }

        &--info-title {
            font-weight: bold;
            font-size: 34px;
            word-break: break-word;
        }

        &--info-sns {
            display: flex;
            flex-wrap: nowrap;
            margin-top: 10px;
            justify-content: center;

            @include tablet {
                justify-content: left;
            }

            & p {
                padding-top: 4px;
            }

            & .btn-edit-sns {
                margin-left: 15px;
            }
        }

        &--sns-icon {
            margin-right: 10px;
        }

        & .img-x {
            height: 40px !important;
        }

        &--info-sns-modal {
            padding-left: 15%;
            padding-right: 15%;
            margin-bottom: 10px;

            @include mobile {
                padding-left: 0;
                padding-right: 0;
            }

            &--twitter {}
        }


        &--link-input-wrapper {
            display: flex;
            align-items: end;
        }

        &--link-input {
            flex: 1;
        }

        &-blog {
            .blog-item-dev {
                width: '100%';
            }

            &__metadata {
                display: flex;

                &--comment-count,
                &--like-count {
                    margin-left: 10px;
                    display: flex;
                    gap: 5px;
                }
            }
        }

        @include mobile-xxl {
            display: block;
            text-align: center;
        }
    }

    &__session {
        &--header {
            border-bottom: 1px solid currentColor;
            display: flex;
            justify-content: space-between;
        }

        &--header-title {
            font-size: 22px;

            @include tablet {
                font-size: 30px;
            }
        }

        &--content {
            padding: 20px 0;
        }
    }

    &__radio-group {
        margin-top: 5px;
        display: flex;
        justify-content: center;
    }

    &__radio-group>label:first-child {
        @include mobile {
            margin-left: 0 !important;
            margin-right: 15px !important;
        }

        margin-left: 30px !important;
        margin-right: 75px !important;
    }

    &__menu {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;

        &--item {
            display: flex;
            border-radius: 20px;
            padding: 3px 10px;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            &--circle {
                width: 26px;
                height: 26px;
                border-radius: 50%;
                align-items: center;
                display: flex;
                justify-content: center;

                svg {
                    font-size: 12px;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.artist-avatar {
    &__backdrop-avatar {
        width: 180px;
        height: 180px;
        border-radius: 15px;
        overflow: hidden;

        @include mobile-xxl {
            margin: auto;
        }

        @include tablet {
            width: 200px;
            height: 200px;
        }

        @include desktop {
            width: 300px;
            height: 300px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.ant-list,
.ant-list-item {
    color: unset;
}