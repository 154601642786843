@import './groups-info';
@import './groups-group-image';
@import './group-actions';
@import './group-blogs';

.portal__wrapper.group__background-color {
    background-color: white;
}

.group__container-color {
    background-color: var(--color-white);
}

.portal-groups {
    background-color: var(--color-white);

    @media (max-width: 1200px) and (min-width: 768px) {
        margin-left: 15px;
        margin-right: 15px;
    }

    &__container {
        max-width: 1170px;
        margin: 0 auto;
        overflow: hidden;

        @include mobile {
            padding: 45px 0 0 0;
        }
    }

    &__header {
        position: fixed;
        left: 0;
        top: -1px;
        z-index: 99;
        width: 100%;
        max-width: 100vw;
        height: 67px;

        &__wrapper {
            background-color: var(--color-black);
            width: 100%;
            // height: 100%;
            height: 67px;

            @include tablet {
                width: 100%;
                margin: 0 auto;
            }

            @include mobile-xxl {
                width: 100%;
                margin: 0 auto;
            }

            @include mobile {
                margin: 0 auto;
            }
        }

        &__fixed-content {
            &__container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 67px;

                @include mobile-xxl {
                    width: 100%;
                }
            }
        }

        &__side-menu {
            &-wrapper {
                height: 31px;
                display: flex;
                align-items: center;
                margin-right: 10px;
            }

            &-search {
                &__button {
                    background-color: inherit;
                    border: none;
                    display: flex;
                    align-items: center;

                    &__icon {
                        svg {
                            cursor: pointer;
                            fill: var(--color-light-gray);
                        }
                    }
                }
            }

            &-menu {
                &__button {
                    border: none;

                    &__icon {
                        color: var(--color-white);
                        font-size: 25px;
                        cursor: pointer;
                    }
                }
            }
        }

        &__navigation {
            width: 100%;
            max-width: 100vw;
            position: fixed;
            top: 66px;
            left: 0;
            z-index: 99;

            &-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-around;
            }

            &-button {
                font-size: 30px;
                color: var(--color-primary);

                &__icon {
                    vertical-align: 0.125rem;
                }
            }

            &-search {
                display: inline-block;
                width: 100%;
                margin-left: 30px;
            }
        }
    }

    &__logo {
        font-size: 24px;

        &__container {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            padding-left: 30px;
        }

        &__icon {
            color: var(--color-white);
        }

        &__text {
            text-transform: uppercase;
            font-weight: bolder;
            margin-left: 5px;
            font-size: 24px;
            color: var(--color-white);

            font-family: Impact,
            Haettenschweiler,
            'Arial Narrow Bold',
            sans-serif &-beta {
                color: var(--color-white);
                font-size: 20px;
                font-weight: bolder;
                margin-left: 5px;
            }
        }
    }

    &__artist {
        &__live-list {
            margin-bottom: 20px;
        }
    }

    &__page {
        min-height: 60vh;
        padding-top: 20px;
        margin: 10px;
    }

    &__input {
        &-search {
            border: 1px solid var(--color-primary);
            border-radius: 20px;
            box-shadow: var(--color-lighter) 0 0 7px;

            &>span {
                color: var(--color-light-gray);
            }
        }
    }

    &__menu {
        padding: 0 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(var(--color-white-rgb), 0.3);

        &__nav {
            // margin: 5px 0;
            padding: 5px 0;
            width: 100%;
            flex-shrink: 1;
            overflow: hidden;

            .slick-track {
                display: flex;
                justify-content: center;
            }

            @include desktop {
                // margin-left: 25px;
            }

            .portal-groups-list-menu {
                text-align: center;

                @include desktop {
                    .slick-list {
                        display: flex !important;
                        justify-content: center !important;

                        .slick-track {
                            transform: none !important;
                        }
                    }
                }

                .list-button-menu {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                }
            }

            &__item {
                margin-top: 5px;
                padding: 5px 0;
                width: max-content;

                .nav__item {
                    position: relative;
                    z-index: 80;
                    color: var(--color-black);
                    border: none;
                    background-color: transparent;
                    box-shadow: none;
                    font-size: 16px;
                    padding: 5px 16px;
                    height: 32px;
                    border-radius: 50px;
                    margin-right: 8px;
                    transition: all 0.25s ease-in;
                    border: 1px solid var(--color-black);
                    font-family: 'Times New Roman', Times, serif;

                    @include desktop {
                        margin-right: 15px;
                        font-size: 22px;
                        border-radius: 25px;
                        padding: 8px 20px;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--color-gray);
                        background-color: var(--color-white);
                    }
                }

                &.active {
                    .nav__item {
                        color: var(--color-white);
                        background-color: var(--color-black);
                    }
                }

                .slick-list {
                    align-items: center;
                }
            }

            .slick-track {
                display: flex;
            }
        }
    }

    .group-top__carousel {
        width: 100%;
        margin-bottom: 25px;
        padding-top: 20px;

        @include mobile {
            padding-left: 15px;
            padding-right: 15px;
        }

        &__wrapper {
            display: flex;

            @media (max-width: 1023px) {
                justify-content: center;
            }

            @include mobile {
                justify-content: space-around;
            }
        }
    }

    .group {
        &__heading {
            // padding: 4px 0;
            background-color: var(--color-white);
            border-radius: 70px;
            font-family: 'Times New Roman', Times, serif;
            font-weight: 700;

            p {
                color: var(--color-black);
                font-size: 24px;
                line-height: 21px;

                @include tablet {
                    font-size: 28px;
                    line-height: 28px;
                    font-family: 'Times New Roman', Times, serif;
                }

                @include desktop {
                    font-size: 28px;
                    line-height: 28px;
                    font-family: 'Times New Roman', Times, serif;
                }
            }
        }
    }

    .portal__btn {
        width: 220px;
        height: 32px;
        color: var(--color-white);
        background-color: #f05454;      
    }
}

.group__btn {
    color: #f05454;
    background-color: var(--color-white);
    font-size: 18px;
    padding: 0 16px;
    line-height: 32px;
    height: 32px;
    width: 230px;
    border: 1px solid #f05454;
    transition: all 0.25s ease-in;

    &:hover,
    &:focus,
    &:active {
        opacity: 0.5;
    }

    a {
        color: inherit;
        text-decoration: none;

        &:link,
        &:visited {
            color: inherit;
        }

        &:focus {
            outline: none;
        }
    }

    &-rounded {
        border-radius: 16px;

        &:hover {
            opacity: 0.5;
        }

        &:focus,
        &:active {
            opacity: 0.5;
        }
    }

    &-small {
        font-size: 10px;
    }
}

.group-img-carousel {
    width: 100%;

    &__wrapper {
        &__carousel {
            position: relative;
            width: 60%;
            height: auto;

            @include desktop {
                width: 95%;
                margin: 0 auto;
            }

            .slick-list {
                width: 100%;
                height: auto;
                background-color: var(--color-white);

                img {
                    @include tablet {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                @include tablet {
                    max-height: 200px;
                    margin: auto 0;
                }
            }

            &__chevron {
                position: absolute;
                bottom: 50%;
                width: 100%;
                display: flex;
                justify-content: space-between;

                &__prev {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    color: var(--color-black);

                    @include mobile-xxl {
                        width: 10px;
                        left: -12px;
                    }

                    @include tablet {
                        width: 20px;
                        left: -20px;
                    }

                    @include desktop {
                        width: 20px;
                        left: -30px;
                    }
                }

                &__next {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    color: var(--color-black);

                    @include mobile-xxl {
                        width: 10px;
                        right: -12px;
                    }

                    @include tablet {
                        width: 20px;
                        right: -20px;
                    }

                    @include desktop {
                        width: 20px;
                        right: -30px;
                    }
                }
            }
        }
    }

    @include tablet {
        .ant-carousel .slick-slider .slick-list {
            height: 60vh;
        }
    }

    @include tablet {
        .ant-carousel .slick-slider .slick-list {
            height: 100vh;
        }
    }

    &__item {
        position: relative;
        width: 100%;

        @include mobile-xxl {
            height: 120px;
        }

        overflow: hidden;

        &__badge {
            position: absolute;
            top: 50px;
            left: 20px;
            color: var(--color-white);
            background: var(--color-accent);
            padding: 5px 10px;
            line-height: 1;
            text-transform: uppercase;
            border-radius: 12px;
            height: 24px;
        }

        &-image {
            width: 100%;
            height: 60%;
            overflow: hidden;
            background-color: var(--color-white);
        }

        img {
            width: 100%;
            height: 60% !important;
            object-fit: cover;
            object-position: center;
        }
    }
}

.group-img-nowOnAir-title {
    font-size: 10px !important;
    letter-spacing: 1px;
    color: #F05454;
    font-weight: 600;
    font-family: 'Ryumin Pro';
    margin-left: 20px;

    @include mobile {
        margin-left: 5px;
        font-size: 9px !important;
        letter-spacing: 0.5px;
    }
}

.group-img-nowOnAir-display {
    @include mobile-xxl {
        display: inline;
    }

    @include desktop {
        display: none;
    }
}

.group-img-nowOnAir {
    width: 110px;
    height: 110px;
    border-radius: 100%;
    filter: drop-shadow(4.474px 4.252px 0px #616161);
    background-color: #F05454;
    margin-left: 23px;
    margin-top: 10px;
    text-align: center;

    p {
        color: #F05454;
        font-family: 'Times New Roman', Times, serif;
        font-size: 24px;
        font-weight: 700;
        // margin-top: 35px;
        padding-top: 10px;
    }

    .group-img-nowOnAir-p {
        padding-top: 0px;
    }
}

.group-img-offOnAir-title {
    font-size: 10px !important;
    letter-spacing: 1px;
    color: #989797;
    font-weight: 600;
    font-family: 'Ryumin Pro';
    margin-left: 20px;

    @include mobile {
        margin-left: 5px;
        font-size: 9px !important;
        letter-spacing: 0.5px;
    }
}

.group-img-offOnAir-display {
    @include mobile-xxl {
        display: inline;
    }

    @include desktop {
        display: none;
    }
}

.group-img-offOnAir {
    width: 110px;
    height: 110px;
    border-radius: 100%;
    filter: drop-shadow(4.474px 4.252px 0px #616161);
    background-color: #989696;
    margin-left: 23px;
    margin-top: 10px;
    text-align: center;

    @include mobile {
        margin: 10px auto;
    }

    p {
        color: var(--color-white);
        font-family: 'Times New Roman', Times, serif;
        font-size: 24px;
        font-weight: 700;
        // margin-top: 35px;
        padding-top: 10px;
    }

    .group-img-nowOnAir-p {
        padding-top: 0px;
    }
}

.group-button-nowOnAir-div {
    text-align: center;
    color: #ff0000;
    font-size: 16px;
    font-weight: 700;
    // @include mobile-l {
    //     display: none;
    // }
    // @include mobile-xxl {
    //     display: none;
    // }
    @include tablet {
        font-size: 18px;
    }
    @include desktop {
        display: flex;
    }

    .group-button-nowOnAir-p {
        width: 285px;
        padding: 7px 25px;
        border-radius: 80px;
        filter: drop-shadow(6.474px 6.252px 0px #490205);
        background-color: #F05454;
    }

    .group-button-nowOnAir {
        text-align: center;

        button {
            text-align: center;

            .group-button-nowOnAir-p {
                margin-top: 10px;
                margin-bottom: 10px;
                margin-left: 5px;

                p {
                    font-family: 'Times New Roman', Times, serif;
                    color: var(--color-white);
                    font-size: 30px;
                    // padding-top: 32px;
                    font-weight: 700;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            p {
                font-weight: 700;
                font-size: 30px;
            }
        }
    }
}

.portal-groups-icon-heart {
    position: relative;
    z-index: 90;
    bottom: 80px;
    height: 0px;

    @include mobile {
        top: -75px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    p {
        font-size: 22px;
        font-weight: 700;
        filter: drop-shadow(0.719px 0.695px 6px #000000);
        color: var(--color-white);

        @include desktop {
            font-size: 25px;
        }
    }

    .portal-groups-icon-heart-fav {
        display: flex;
        background-color: var(--color-white);
        border: 2px solid #f05454;
        border-radius: 18px;

        @include desktop {
            border-radius: 25px;
            width: 240px;
            text-align: center;
            margin-top: 10px;
        }

        img {
            width: 18px;
            height: 18px;
            margin-top: 7px;
            margin-left: 5px;

            @include desktop {
                width: 25px;
                height: 25px;
                margin-top: 10px;
                margin-left: 12px;
            }
        }

        p {
            padding: 6px 4px;
            font-size: 12px;
            font-weight: 700;
            color: #f05454;
            filter: none;

            @include desktop {
                font-size: 20px;
                padding-right: 10px;
            }
        }
    }
}

.label-now-on-air {
    text-align: center;

    @include mobile-xxl {
        margin-top: 20px;
    } 
}
.group-button-offOnAir-p {
    width: 285px;
    padding: 5px 40px;

    @include mobile {
        padding: 0 20px;
    }

    border-radius: 80px;
    filter: drop-shadow(6.474px 6.252px 0px #2b2b2b);
    background-color: #989696;
    margin-top: 10px;
    margin-bottom: 15px;

    p {
        font-family: 'Times New Roman', Times, serif;
        color: var(--color-white);
        font-size: 30px;
        font-weight: 700;
    }
}

.group-button-nowOnAir-p {
    width: 285px;
    padding: 5px 40px;
    @include mobile {
        padding: 0 20px;
    }
    
    @include mobile-xxl {
        margin: auto;
    } 

    border-radius: 80px;
    filter: drop-shadow(6.474px 6.252px 0px #490205);
    background-color: #F05454;
    margin-top: 10px;

    p {
        font-family: 'Times New Roman', Times, serif;
        color: var(--color-white);
        font-size: 30px;
        font-weight: 700;
    }
}

.portal-groups-group-image-large {
    padding-top: 0px;
}

.group-img-center-background {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: cover;
    // background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    // filter: blur(5px);
    @include tablet {
        // width: 315px;
        width: 400px;
        // height: 315px;
        height: 400px;
        top: 40%;
    }

    @include desktop {
        // width: 315px;
        width: 400px;
        // height: 315px;
        height: 400px;
        top: 45%;
    }
}

.group-img-background {
    width: 1170px;
    height: 580px;

    @include mobile {
        width: 100vw;
        height: 283px;
        background-repeat: no-repeat;
        background-position: center;
    }

    @include mobile-l {
        width: 100vw;
        height: 283px;
        background-repeat: no-repeat;
        background-position: center;
    }

    @include tablet {
        width: 1170px;
        height: 580px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.group-img-center {
    width: 175px !important;
    height: 125px !important;
    margin: 0 auto;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include tablet {
        width: 315px !important;
        height: 210px !important;
        top: 40%;
    }

    @include desktop {
        width: 315px !important;
        height: 210px !important;
        top: 45%;
    }
}

.group-image-fix {
    width: 100%;
    height: 100%;
}

.now-on-air-text {
    font-size: 19px;
    margin-left: 5px;
}

.title-now-off-air {
    font-size: 19px;
    margin-left: 5px;
    color: #989696;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    font-weight: 500;
}

.name-title-group {
    @include mobile {
        padding-top: 55px;
    }
}