.portal-banner {
    width: 100%;
    @include tablet {
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    &__heading {
        margin: 0px 15px;
        padding: 3px 14px;
        background-color: var(--color-black);
        border-radius: 70px;
        color: $color-p-white;
        font-size: 16px;
        font-weight: 700;
        @include tablet {
            padding: 6px 16px;
            font-size: 25px;
        }
    }
    &__heading-fix {
        margin: 0px 15px;
        padding: 3px 14px;
        background-color: var(--color-black);
        border-radius: 70px;
        color: $color-p-white;
        font-size: 16px;
        font-weight: 700;
        @include tablet {
            padding: 6px 0px;
            font-size: 25px;
        }
    }
    &__wrapper {
        // margin: 0 20px;
        @include tablet {
            display: flex;
            max-width: 1100px;
            justify-content: space-evenly;
        }
    }
    &__wrapper-nft-mall-top {
        // margin: 0 20px;
        @include tablet {
            display: flex;
            justify-content: space-evenly;
        }
    }
    &__nft {
        margin: 30px 10px 10px;
        @include tablet {
            display: none;
        }

        @include mobile {
            margin: 30px 0px;
        }
    }
    &__nft--pc {
        margin: 50px auto 20px;
        max-width: 1100px;
        @include mobile-xxl {
            display: none;
        }
    }

    &__nft--pc-fix {
        margin: 0 auto;
        // max-width: 1100px;
        // margin: 0 15px;
        margin-top: 40px;
        @include mobile-xxl {
            display: none;
        }
    }
    &__link {
        text-align: right;
        font-size: 11px;
        font-weight: 600;
        margin-bottom: 50px;
        padding-right: 24px;
        text-decoration: underline;
        letter-spacing: 1px;
        @include tablet {
            font-size: 11px;
            padding-right: 30px;
        }
    }
    &__item {
        position: relative;
        width: 100%;
        max-height: 180px;
        overflow: hidden;
        margin-bottom: 25px;
        border-radius: 10px;
        @include tablet {
            width: 48%;
            border-radius: 10px;
        }
        &-image {
            width: 100%;
            height: auto;
            overflow: hidden;
            img {
                margin-left: 25px;
                width: 95%;
                height: 95%;
                object-fit: cover;
                object-position: center;
                @include mobile {
                    // margin-left: 7px;
                    margin-left: 0;
                    width: 100%;
                }

            }
            &-no-available {
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }

        &-image-fix {
            width: 100%;
            height: auto;
            overflow: hidden;
            // margin: 0 15px;
            img {
                // margin-left: 25px;
                // @include mobile {
                //     margin-left: 7px;
                // }
                width: 100%;
                height: auto;
                object-fit: cover;
                object-position: center;
            }
            &-no-available {
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
    }

    &__item__fix {
        width: 100%;
        height: auto;
        overflow: hidden;
        @include tablet {
            width: 48%;
            border-radius: 10px;
        }
        &-image {
            img {
                width: 100%; 
                height: 100%;
            }
        } 
        &-no-available {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }
}

.portal-banner-wrapper-fix-max-width {
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 0 30px;

    @include mobile {
        padding: 0 5px;
        img {
            width: 100% !important;
        }
    }


    @media (max-width: 767px) {
        margin-top: 20px;
        flex-direction: column;
        gap: 30px;
        width: 95%;

        img {
            width: 95%;
            height: auto;
            margin: 0 auto;
            border-radius: 10px;
        }
    }
}

.portal-banner-wrapper {
    // background-color: red;
    margin: 0 15px;
}