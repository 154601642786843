.gacha-detail {
    margin: 117px auto 0 auto;
    max-width: 980px;
    
    &__content {
        padding: 0 20px;
    }

    &__name, &__coin {
        color: $color-black;
        font-size: 22px;
        font-weight: bolder;
        text-align: center;
    }

    &__name {
        margin-top: 32px;
    }

    &__coin {
        margin-bottom: 32px;
    }

    &__note-content {
        color: $color-black;
    }

    &__info {
        &-cover {
            display: flex;
            justify-content: center;
            &-image {
                width: 100%;
                height: auto;
            }
        }
    }

    &__win-product {
        margin: 12px 0px;
        &-item {
            width: 100%;
            height: 100%;
        }
    }

    &__note {
        margin: 32px 0px;
        &-title {
            text-align: center;
            text-decoration: underline;
            font-weight: bolder;
            margin-bottom: 8px;
            font-size: 22px;
        }
        &-content {
            text-align: justify;
        }
    }

    &__info-banner {
        width: 100%;
        margin: 20px 0;

        &--image {
            width: 100%;
            height: 980px;
            object-fit: cover;
        }
    }

 
    &__policy {
        &--detail {
            padding: 0 20px;
            border: 1px solid $color-black;
            height: 250px;
            overflow-y: auto;
        }

        &--label {
            width: 100%;
            justify-content: center;
            display: flex;
            align-items: center;

            input {
                width: 25px;
                height: 25px;
            }
        }
    }

    &__btn-play-gacha {
        background-color: $color-red;
        height: 75px;
        font-family: 'Pyumin Pro';
    }
    
    &__btn-purchase-coin {
        letter-spacing: 2;
        line-height: 1.2;
        padding: 6.5px 5px;
        height: 75px;
        font-family: 'Pyumin Pro';
    }

    &__title-policy {
        padding: 5px 0;
    }

    &__button-guild {
        height: 75px;
        font-family: 'Pyumin Pro';
    }
}


@media screen and (max-width: 767.9px) {
    .gacha-detail {
        margin: 65px auto 0 auto;
        max-width: 390px;

        &__info-banner {
            &--image {
                height: 390px;
            }
        }

        &__title-policy {
            font-size: 14px;
            padding: 5px 0;
        }

        &__policy {
            &--checkbox-text {
                font-size: 12px;

                span {
                    display: block;
                }
            }
        }

        &__policy {
            &--label {
    
                input {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    
    }
}