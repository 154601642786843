.portal-pick-up {
    &__wrapper {
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
        overflow: hidden;
        @include tablet {
            margin-bottom: 70px;
        }
    }
    &__list {
        display: flex;
        position: relative;
        padding: 0 16px;
        padding-top: 8px;
        width: 100%;
        max-width: 1720px;
        margin: 0 auto;
        @include tablet {
            padding: 0;
        }
        &__pc {
            display: none;
            @include tablet {
                display: block;
                &__btn-next {
                    display: block;
                    height: 20px;
                    width: 24px;
                    position: absolute;
                    right: 4px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    img {
                        width: 100%;
                    }
                }

                &__btn-next-fix {
                    display: block;
                    height: 20px;
                    width: 24px;
                    position: absolute;
                    right: -14px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    img {
                        width: 100%;
                    }
                }
                &__btn-prev {
                    display: block;
                    height: 20px;
                    width: 24px;
                    position: absolute;
                    left: 4px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        &__content {
            position: relative;
            height: 122px;
            width: 50%;
            // width: 200px;
            border: 1px solid var(--color-border-portal);
            &:nth-child(2n) {
                margin-left: 8px;
            }
            @include tablet {
                height: 190px;
                width: 33.3333%;
                &:nth-child(2n) {
                    margin: 0 14px;
                }
            }
            &::after {
                position: absolute;
                content: '';
                width: 12px;
                height: 12px;
                right: 0px;
                bottom: 6%;
                background-color: var(--color-white);
                border-radius: 50%;
                transform: translateX(50%);
                border: 1px solid var(--color-border-portal);
                @include tablet {
                    width: 22px;
                    height: 24px;
                    right: 0px;
                    bottom: 9%;
                }
            }
            &::before {
                position: absolute;
                content: '';
                width: 8px;
                height: 20px;
                right: 0px;
                bottom: 6%;
                transform: translateX(115%);
                background-color: var(--color-white);
                z-index: 2;
                @include tablet {
                    width: 12px;
                    height: 30px;
                    right: -1px;
                    bottom: 8%;
                    transform: translateX(100%);
                }
            }
            &__pc {
                display: none;
                @include tablet {
                    display: block;
                }
            }
        }
        &__content__fix {
            position: relative;
            // height: 122px;
            width: 100%;
            &:nth-child(2n) {
                margin-left: 8px;
            }
            @include tablet {
                height: 190px;
                // margin: 0 5px;
                margin-right: 5px;
                &:nth-child(2n) {
                    margin: 0 14px;
                }
            }
            &__pc {
                display: none;
                @include tablet {
                    display: block;
                }
            }
        }
        &__item {
            display: flex;
            img {
                width: 94px;
                height: 94px;
                object-fit: cover;
                object-position: center;
                @include tablet {
                    height: 188px;
                    width: 55%;
                }
            }
            &__context {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;
                opacity: 0.6;
                background-color: var(--color-secondary);
                @include tablet {
                    height: 120px;
                }
                p {
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--color-white);
                    @include tablet {
                        font-size: 20px;
                    }
                }
                &__color {
                    background-color: #c69be7;
                }
            }
        }
        &__item__fix {
            width: 100%;
            height: 100%;
            padding-right: 20px;
            img {
                width: 100%;
                height: 100%;
                // object-fit: cover;
            }
        }
        &__tittle {
            display: block;
            position: absolute;
            left: 2px;
            top: 89px;
            width: 100%;
            overflow: hidden;
            p {
                font-size: 8px;
                font-weight: 800;
                text-align: center;
                color: var(--color-black);
                text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
                    1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
                @include tablet {
                    font-size: 12px;
                    text-align: left;
                    &:first-child {
                        white-space: nowrap;
                    }
                }
            }
            &__secondary {
                p:last-child {
                    font-weight: 800;
                    color: var(--color-secondary);
                }
            }
            @include tablet {
                position: absolute;
                left: 56%;
                top: 110px;
                width: 40%;
                overflow: hidden;
            }
        }
    }
    &__heading {
        display: flex;
        margin-bottom: 34px;
        p {
            color: var(--color-white);
            font-weight: 600;
        }
        img {
            height: 18px;
            margin-left: 6px;
            @include tablet {
                height: 24px;
                margin-left: 10px;
            }
        }
    }
}

.portal-pick-up__heading.portal-heading {
    background-color: var(--color-secondary);
    @include tablet {
        width: 190px;
    }
}
