.portal-artists {
    width: 100%;
    &__filter {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        width: 100%;

        &__categories {
            width: 100%;
            flex-shrink: 1;
            margin-bottom: 10px;
            overflow-x: auto;
            @include tablet {
                margin-bottom: 20px;
            }
            &__wrapper {
                width: 414px;
                display: flex;

                &--button {
                    margin-right: 12px;
                    text-decoration: underline;
                    font-size: 18px;
                    color: #000;
                }
                &--active {
                    color: red;
                    margin-right: 12px;
                    text-decoration: underline;
                    font-size: 18px;
                }
            }
            &__item {
                padding: 5px 0;
                &.active {
                    button {
                        color: red;
                        span {
                            text-decoration: underline;
                        }
                        @include tablet {
                            font-size: 20px;
                        }
                    }
                }
                &--button {
                    border: none;
                    background-color: transparent;
                    box-shadow: none;
                    font-size: 16px;
                    padding-top: 2px;
                    padding-bottom: 2px;
                    padding-right: 20px;
                    padding-left: 0 !important;

                    &:hover,
                    &:focus,
                    &:active {
                        border: none;
                        background-color: var(--color-white);
                    }
                    @include tablet {
                        font-size: 20px;
                    }
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }

        &__categories-top-page {
            width: 100%;
            flex-shrink: 1;
            margin-bottom: 10px;
            overflow-x: auto;
            @include tablet {
                margin-bottom: 20px;
                margin-left: 5px;
            }
            &__wrapper {
                width: 414px;
                display: flex;

                &--button {
                    margin-right: 12px;
                    text-decoration: underline;
                    font-size: 18px;
                    color: #000;
                }
                &--active {
                    color: red;
                    margin-right: 12px;
                    text-decoration: underline;
                    font-size: 18px;
                }
            }
            &__item {
                padding: 5px 0;
                &.active {
                    button {
                        color: red;
                        span {
                            text-decoration: underline;
                        }
                        @include tablet {
                            font-size: 20px;
                        }
                    }
                }
                &--button {
                    border: none;
                    background-color: transparent;
                    box-shadow: none;
                    font-size: 16px;
                    padding: 2px 10px;
                    &:hover,
                    &:focus,
                    &:active {
                        border: none;
                        background-color: var(--color-white);
                    }
                    @include tablet {
                        font-size: 20px;
                    }
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }
        &__search {
            width: 100%;
            box-sizing: border-box;
            border: 1px solid #a3a1a1;
            border-radius: 20px;
            display: flex;
            align-items: center;
            padding: 0 0 0 10px;
            &--input {
                width: 91%;
                border: none;
                background-color: transparent;
                padding: 6px 12px 6px 3px;

                @media (max-width: 770px) {
                    margin-right: 0px !important;
                }

                &:focus {
                    outline: none;
                }
                @include tablet {
                    width: 95%;
                    padding: 8px 10px;
                    font-size: 18px;
                    margin-right: 20px;
                }
            }
            &--icon {
                height: 20px;
                cursor: pointer;
            }
        }

        &__search-top-page {
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
            border: 1px solid #a3a1a1;
            border-radius: 20px;
            display: flex;
            align-items: center;
            padding: 0 0 0 10px;
            @include tablet {
                background-color: blue;
                margin-left: 15px !important;
                margin-right: 15px !important;
            }
            &--input {
                width: 90%;
                border: none;
                background-color: transparent;
                padding: 5px 10px;
                &:focus {
                    outline: none;
                }
                @include tablet {
                    width: 96%;
                    padding: 8px 10px;
                    font-size: 18px;
                }
            }
            &--icon {
                height: 20px;
                cursor: pointer;
            }
        }
    }
    &__container {
        padding: 0 10px;
        @include tablet {
            padding: 0;
            // margin-bottom: 50px;
        }
    }
    &__list {
        &-item {
            // margin-bottom: 16px;
            &__wrapper {
                position: relative;
                width: 100%;
                overflow: hidden;
                margin-bottom: 8px;
                background-color: var(--color-light-gray);
                box-shadow: rgba(var(--color-black-rgb), 0.1) 0 0 15px;
                border-radius: 10px;
                &:after {
                    display: block;
                    content: '';
                    padding-bottom: 100%;
                }
            }
            &__thumbnail {
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 8px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            &__stats {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                padding: 5px;
                background-color: rgba(var(--color-white-rgb), 0.5);
                color: var(--color-black);
                &__follower,
                &__like {
                    font-size: 12px;
                    span.anticon {
                        vertical-align: 0.125em;
                        margin-right: 5px;
                    }
                }
                &__like {
                    span.anticon {
                        color: var(--color-secondary);
                    }
                }
            }
            &--text {
                font-size: 14px;
                color: var(--color-black);
                text-decoration: underline;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    &__list-top-page {
        @include tablet {
            margin-right: 15px !important;
            margin-left: 15px !important;
        }
        &-item {
            margin-bottom: 16px;
            &__wrapper {
                position: relative;
                width: 100%;
                overflow: hidden;
                margin-bottom: 8px;
                background-color: var(--color-light-gray);
                box-shadow: rgba(var(--color-black-rgb), 0.1) 0 0 15px;
                border-radius: 10px;
                &:after {
                    display: block;
                    content: '';
                    padding-bottom: 100%;
                }
            }
            &__thumbnail {
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 8px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            &__stats {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                padding: 5px;
                background-color: rgba(var(--color-white-rgb), 0.5);
                color: var(--color-black);
                &__follower,
                &__like {
                    font-size: 12px;
                    span.anticon {
                        vertical-align: 0.125em;
                        margin-right: 5px;
                    }
                }
                &__like {
                    span.anticon {
                        color: var(--color-secondary);
                    }
                }
            }
            &--text {
                font-size: 14px;
                color: var(--color-black);
                text-decoration: underline;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
    &--pc {
        @include tablet {
            display: block;
        }
        @include mobile-xxl {
            display: none;
        }
    }
    &--mobile {
        @include tablet {
            display: none;
        }
        @include mobile-xxl {
            display: block;
        }
    }
}
.ant-row {
    flex-flow: nowrap;
    overflow: hidden;
}

.artist-input-search-wrapper {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #a3a1a1;
    border-radius: 20px;
    display: flex;
    align-items: center;

    @include tablet {
        width: 100%;
    }
    .input-artist-search-style {
        width: 91%;
        border: none;
        background-color: transparent;
        padding: 6px 12px;
        &:focus {
            outline: none;
        }
        @include tablet {
            width: 96%;
            padding: 8px 10px;
            font-size: 18px;
        }
    }

    .icon-artist-search-style {
        height: 20px;
        cursor: pointer;
    }
}
