.portal-call-for-artists {
    margin-top: 67px;
    font-size: 14px;
    &__cover {
        width: 100%;
        height: 240px;
        overflow: hidden;
        &__container {
            width: 100%;
            height: 100%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    &__wrapper {
        margin: 0 20px;
    }
    &__header {
        margin-top: 20px;
        &__heading {
            font-size: 18px;
            font-weight: bolder;
            text-decoration: underline;
            text-align: center;
            span {
                font-size: 20px;
                color: var(--color-primary);
            }
        }
        &__content {
            margin-top: 20px;
        }
    }
    &__content {
        margin-top: 25px;
        &__wrapper {
            padding-bottom: 30px;
        }
        &__heading {
            border: 1px solid var(--color-heavy-gray);
            padding: 12px 0;
            text-align: center;
        }
        &__main {
            margin-top: 25px;
        }
        &__link {
            color: var(--color-primary);
            background-color: var(--color-white);
            border: 1px solid var(--color-primary);
            margin: 0 120px;
            margin-top: 45px;
            padding: 5px 0;
            text-align: center;
            border-radius: 300px;
            box-shadow: var(--color-primary) 0 0 6px;
        }
    }
}