.portal-creator-list {
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
}

.creator-list-row {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .creator-list-col {
        padding: 10px 11px;
        @include mobile {
            padding: 10px 11px;
        }
        @include desktop{
            padding: 20px 20px;
        }
        p {
            margin-top: 3px;
            @include desktop{
                margin-top: 8px;
                font-size: 20px;
                text-align: center;
            }
        }
    }
}

.creator-list-item-col {
    position: relative;
    width: 100%;
    margin-bottom: 8px;
    &:after {
        display: block;
        content: '';
        padding-bottom: 88%;
    }
}

.creator-list-name-item {
    font-size: 20px;
    @include mobile {
        font-size: 16px;
    }
}

.creator-list-page-container {
    max-width: 1200px;
    margin: 0 auto;
}

.portal-creator-list {
    @include desktop {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.portal-creatorList-btn-menu {
    padding-left: 0px;
    padding-right: 20px;
}

.portal-create-list-boxshadow-none {
    box-shadow: none;
}

.protal-creator-list-pagination {
    text-align: center;
}

.creator-list-item-img-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 245px;
    @include mobile {
        height: 140px;
    }
    @include desktop-l{
        height: 200px;
    }
}

.margin-pagination {
    margin-top: 25px;
}