.portal--artist--ranking {
  max-width: 1200px;
	margin-top: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    @include mobile-xxl {
        padding: 0 10px;
    }
	&__title {
		text-align: left;
		font-size: 28px;
		color: #000;
		font-weight: 700;
		margin-bottom: 30px;
		@include mobile-xxl {
			font-size: 20px;
			margin-bottom: 10px;
		}
	}

	&__content {
		width: 56%;
		margin: 0 25% 0 20%;
		@include mobile-xxl {
			width: 100%;
			margin: 0;
		}
		&__modal {
			border: 1px solid #d2d2d2;
			border-radius: 10px;
			padding: 10px 20px;
			display: flex;
			align-items: center;
			margin: 10px 5px 10px 0;
			min-height: 110px;
			@include mobile-xxl {
				padding: 10px 30px;
			}
			&__avatar {
				width: 100px;
				@include mobile-xxl {
					width: 80px;
					&__award {
						width: 50px;
						height: auto;
					}
				}
			}
			&__wrap {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 60%;
				margin: 0 auto;
				@include mobile-xxl {
					flex-direction: column;
					justify-content: space-between;
					width: 70%;
				}
				&__name {
					font-size: 20px;
					font-weight: 700;
					color: #000;
					@include mobile-xxl {
						font-size: 18px;
					}
				}
				&__point {
					font-size: 20px;
					font-weight: 700;
					color: #ff0000;
					@include mobile-xxl {
						font-size: 25px;
					}
				}
			}
		}
	}
	&__ranking {
		&__footer {
			p {
				font-size: 30px;
				color: #000;
			}
		}
	}
}
