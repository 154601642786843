.portal-artist-live-tickets {
    max-width: 1170px;
    margin: 0 auto;
    &__container {
        background-color: var(--color-white);
        padding: 20px;
        border-radius: 8px;
        box-shadow: rgba(var(--color-black-rgb), 0.1) 0 0 15px;
        margin-bottom: 20px;
    }
    &__header {
        margin-bottom: 20px;

    h3 {
      color: var(--color-dark);
      margin: 0;
      padding: 0;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: bolder;
    }
    }
    &__list {
        .ant-list {
            &-item {
                padding: 20px 0;
                &-action {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 10px;
                    &>li {
                        padding: 0;
                    }
                }
                &-no-flex {
                    margin-bottom: 12px;
                }
                &-meta {
                    &-title {
                        display: flex;
                        justify-content: center;
                        font-size: 14px;
                    }
                    &-description {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-size: 12px;
                    }
                    &-avatar {
                        height: 100%;
                        .ant-image {
                            height: 100%;
                            &-mask {
                                z-index: 100;
                            }
                        }
                    }
                }
            }
            &-split {
                .ant-list-item {
                    border-bottom: 1px solid var(--color-lighter-gray);
                }
            }
        }
    }
    &__item {
        &__title {
            &-text {
                color: var(--color-dark);
                &:hover,
                &:focus,
                &:active {
                    color: var(--color-primary);
                }
            }
        }
        &__image {
            &-container {
                max-width: 85px;
                height: 63px;
                overflow: hidden;
                position: relative;
            }
            &-image {
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
            &-sold-out {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 75%;
                z-index: 50;
            }
        }
        &__info {
            &__text {
                &-holding-time {
                    margin-right: 5px;
                }
            }
        }
        &__btn {
            &-btn {
                font-size: 10px;
                padding: 2px 12px;
                &:hover,
                &:focus,
                &:active {
                    color: var(--color-white);
                    background-color: var(--color-primary);
                    border: 1px solid var(--color-primary);
                }
            }
            &-black {
                background-color: #000000;
                border: 1px solid #000000;
            }
        }
    }
}

.header-live-ticket {
    @include mobile {
        margin-top: 15px;
    }

    @include tablet {
        margin-top: 65px;
    }

    @include desktop {
        margin-top: 25px;
    }
}
