.portal-artist-area {
    &__container {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 5px;
        margin-bottom: 30px;
    }
    &__heading {
        margin-bottom: 10px;
        font-size: 16px;
    }
}

.creator-list-wrapper {
}
