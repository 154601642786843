.portal {
    width: 100%;
    height: 100%;
    font-size: 15px;
    color: var(--color-gray);

    &__wrapper {
        position: relative;
        min-height: 100vh;
        display: flex;
        align-content: start;
        justify-content: center;
    }

    &__container {
        background: var(--color-white);
        width: 100%;
        height: 100vh;

        // @include mobile-xxl {
        //     max-width: $max-width;
        // }
        @include tablet {
            max-width: $max-with-pc;
        }
    }

    &__bottom-navigator {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 99;
        width: 100%;
        height: 75px;

        @include tablet {
            display: none;
        }

        &-edit {
            position: relative;

            @include tablet {
                // margin: 40px 0;
            }

            &-button {
                position: absolute;
                right: 3px;
                top: 3px;
                width: 23px;
                z-index: 2;

                @include tablet {
                    display: none;
                }
            }
        }

        &__wrapper {
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            // background-color: rgba(var(--color-black-rgb), 0.96);
            width: 100%;

            @include tablet {
                display: none;
            }
        }

        &__item {
            // width equal to the widest icon
            width: 72px;
            height: 75px;
            display: flex;
            justify-content: center;
            justify-content: end;
            flex-direction: column;
            padding-bottom: 17px;

            &:hover {
                // background-color: rgba(var(--color-black-rgb), 0.1);
            }

            &__link {
                display: flex;
                flex-direction: column;
                font-size: 14px;
                position: relative;
                bottom: -10px;
                text-align: center;

                img {
                    width: 30px;
                    margin-left: 20px;
                }

                p {
                    padding-top: 6px;
                    font-size: 12px;
                    font-weight: 500;
                    // color: var(--color-white);
                }

                .img_icon_nft {
                    height: 29px;
                    width: 32px;
                }
            }

            &-text {
                font-size: 15px;
                margin-top: 2px;
            }
        }
    }

    &__header {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 99;
        width: 100%;
        height: 105px;

        &__hamburger {
            width: 40px;
            height: 40px;
            position: fixed;
            top: 0;
            right: 0;
            z-index: 999;
            background: rgba(255, 255, 255, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__wrapper {
            margin: 0 auto;
            background-color: $color-black;
            padding: 15px 0 0 0;
            width: 100%;

            @include mobile-xxl {
                max-width: $max-width;
            }

            @include tablet {
                max-width: $max-with-pc;
                padding: 10px 0 0 0;
            }
        }

        &__menu__pc {
            @include mobile-xxl {
                display: none;
            }

            @include tablet {
                display: block;
                font-size: 13px;
                background-color: $color-white;
                padding: 15px 10px;
            }

            &--wrapper {
                max-width: 1170px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: space-between;

                // gap: 0px;
                li {
                    :hover {
                        color: $color-p-blue;
                    }
                }

                @media (max-width: 1200px) {
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 20px;
                }
            }
        }

        &__fixed-content {
            &__container {
                display: flex;
                align-items: center;
                justify-content: space-around;
                height: 67px;

                @include tablet {
                    justify-content: center;
                }
            }
        }

        &__side-menu {
            &-wrapper {
                height: 31px;
                display: flex;
                align-items: center;

                @include tablet {
                    display: none;
                }
            }

            &-search {
                &__button {
                    background-color: inherit;
                    border: none;
                    display: flex;
                    align-items: center;

                    &__icon {
                        svg {
                            cursor: pointer;
                            fill: var(--color-light-gray);
                        }
                    }
                }
            }

            &-menu {
                &__button {
                    margin-right: -25px;
                    background-color: inherit;
                    border: none;

                    &__icon {
                        color: $color-portal-lighter-gray;
                        font-size: 33px;
                        font-weight: 300;
                        cursor: pointer;
                    }
                }

                &__overlay {
                    // height: 335px;
                    // overflow-y: scroll;
                }
            }
        }

        &__navigation {
            width: 100%;
            padding: 10px 30px;

            @include tablet {
                padding: 7px;
            }

            &-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-around;
            }

            &-button {
                font-size: 30px;
                color: var(--color-primary);

                &__icon {
                    vertical-align: 0.125rem;
                }
            }

            &-search {
                display: inline-block;
                width: 100%;
                margin-left: 30px;
            }
        }
    }

    &__logo {
        font-size: 24px;

        &__container {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 170px;

                @include tablet {
                    width: 200px;
                }
            }

            span {
                font-size: 36px;
                font-family: 'Krungthep', sans-serif;
                color: #fff;
                text-transform: uppercase;
            }
        }

        &__icon {
            color: var(--color-primary);
        }

        &__text {
            text-transform: uppercase;
            font-weight: bolder;
            margin-left: 5px;
            font-size: 30px;
            color: $color-white;

            &-beta {
                font-size: 30px;
                font-weight: 600;
                margin-left: 10px;
                color: $color-p-white;

                @include mobile-xxl {
                    display: none;
                }

                @include tablet {
                    display: block;
                }
            }
        }
    }

    &-page {
        &__container {
            margin-top: 115px;
        }
    }

    &__btn {
        width: 220px;
        color: var(--color-white);
        background-color: #f05454;
        font-size: 18px;
        padding: 2px 16px;
        line-height: 1;
        height: 32px;
        border: 1px solid var(--color-primary);
        transition: all 0.25s ease-in;

        &:hover,
        &:focus,
        &:active {
            opacity: 0.5;
        }

        &-rounded {
            border-radius: 16px;
        }

        &-container {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            margin: 24px 0;
        }

        &-disabled {
            cursor: not-allowed;

            a {
                pointer-events: none;
            }
        }

        a {
            color: inherit;
            text-decoration: none;

            &:link,
            &:visited {
                color: inherit;
            }

            &:focus {
                outline: none;
            }
        }
    }

    &__btn__favorite {
        color: #f05454;
        border: 1px solid #f05454;
        background-color: var(--color-white);
        font-size: 18px;
        width: 230px;
        height: 32px;
        text-align: center;
        line-height: 1;
        height: 32px;
        transition: all 0.25s ease-in;

        &:hover,
        &:focus,
        &:active {
            color: rgba($color: #f05454, $alpha: 0.5);
            border: 1px solid rgba($color: #f05454, $alpha: 0.5s);
            background-color: var(--color-white);
        }

        &-rounded {
            border-radius: 16px;
        }

        &-container {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            margin: 24px 0;
        }

        &-disabled {
            cursor: not-allowed;

            a {
                pointer-events: none;
            }
        }

        a {
            color: inherit;
            text-decoration: none;

            &:link,
            &:visited {
                color: inherit;
            }

            &:focus {
                outline: none;
            }
        }
    }

    &__btn__gray {
        color: white;
        border: 1px solid #989797;
        background-color: #989797;
        font-size: 18px;
        padding: 2px 16px;
        line-height: 1;
        height: 32px;
        transition: all 0.25s ease-in;
        margin-bottom: 10px;
        margin: 10px auto;
    }

    &__btn__gray__fix {
        width: 200px;
        color: white;
        background-color: #979797;
        font-size: 18px;
        padding: 15px 15px;
        margin: 10px auto;
        border-radius: 50px;
        margin-bottom: 20px
    }

    &__btn__gray__fix__fc {
        width: 200px;
        color: white;
        background-color: #979797;
        font-size: 18px;
        padding: 3px 5px;
        margin: 10px auto;
        border-radius: 50px;
        margin-bottom: 20px;

        @include mobile {
            display: flex;
            justify-content: space-evenly !important;
            align-items: center;
        }
    }

    &__btn__black {
        width: 200px;
        color: white;
        background-color: black;
        font-size: 18px;
        padding: 15px 15px;
        margin: 10px auto;
        border-radius: 50px;
        margin-bottom: 20px;
    }

    &__btn__live {
        width: 200px;
        color: white;
        background-color: #EF5352;
        font-size: 18px;
        padding: 15px 15px;
        margin: 10px auto;
        border-radius: 50px;
        margin-bottom: 20px;
    }

    &__btn__enable_rank {
        width: 200px;
        color: white;
        background-color: #EF5352;
        font-size: 18px;
        padding: 3px 5px;
        margin: 10px auto;
        border-radius: 50px;
        margin-bottom: 20px;

        @include mobile {
            display: flex;
            justify-content: space-evenly !important;
            align-items: center;
        }
    }


    &__footer {
        font-size: 12px;
        padding-bottom: 74px;
        padding-top: 50px;
        white-space: nowrap;
        line-height: 18px;

        @include tablet {
            padding-bottom: 0;
            display: block;
        }

        &__nopadding {
            padding-bottom: 0px;
        }

        &__wrapper {
            padding: 20px;
            width: 100%;
            font-size: 11px;
            color: $color-white;
            background-color: $color-black;
            text-align: right;
        }

        &__container {
            display: flex;
            flex-wrap: wrap;
            // min-width: 360px;
            font-size: 10px;
            letter-spacing: 0.5px;

            @include tablet {
                max-width: 900px;
                margin: 10px auto;
                font-size: 16px;
                line-height: 2;
                letter-spacing: 1px;
            }

            &-row {
                &-large {
                    justify-content: space-around;
                    padding-bottom: 10px;
                }

                &-small {
                    justify-content: center;
                    margin-bottom: 5px;
                }
            }

            &-col {
                flex-direction: column;
            }
        }

        &__item {
            &-bordered {
                padding: 6px 0;
                border: 1px solid var(--color-heavy-gray);
                text-align: center;
                width: 40%;
            }

            &-underlined {
                text-decoration: underline;
                margin-right: 10px;

                @include tablet {
                    margin-right: 16px;
                }
            }

            &-copy_right {
                margin-top: 10px;
                font-size: 9px;

                @include tablet {
                    font-size: 15px;
                }
            }
        }
    }

    &__input {
        &-search {
            border: 1px solid var(--color-primary);
            border-radius: 20px;
            box-shadow: var(--color-lighter) 0 0 7px;

            &>span {
                color: var(--color-light-gray);
            }
        }
    }

    &-coin-gage {
        position: fixed;
        margin-left: 210px;
        bottom: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 60;

        &__display {
            position: relative;
            margin-right: 4px;

            &__point {
                position: absolute;
                top: 0;
                right: 15px;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
}

// change text of cancel btn in modal made by antDesign from en to ja
.ant-modal {
    &-confirm-btns {
        button:nth-of-type(1) {
            padding: 0 7.5px;
            width: 75px;

            span {
                font-size: 0;

                &:before {
                    font-size: 12px;
                    content: '\30AD\30E3\30F3\30BB\30EB'; //convert japanese word "キャンセル" (means cansele) into Unicode character
                }
            }
        }
    }
}

// clear border bottom menu
.ant-menu-horizontal {
    border: none;
}

.skeleton-loading-listfc {
    @include mobile {
        padding-left: 15px;
        padding-right: 15px;
    }

    @include tablet {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.change-rank-fc {
    padding-top: 5px;
    padding-bottom: 5px;
}

.box-picker {
    width: 100%;
    margin: 0 auto;

    .flexbox-fix {
        display: none;
    }
}

.font14 {
    font-size: 14px !important;
}

.label-can-dragging {
    margin-top: 20px;
}
.drap-drop-image {
    // display: flex;
    word-wrap: break-word;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 15px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    &__item {
        text-align: center;

        &-child {
            width: 100%;
            height: 90px;
            position: relative;

            &-image {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                object-fit: contain;
                background-color: #dcdcdc;
            }

            &-checkbox {
                top: 5px;
                right: 5px;
                position: absolute !important;
                width: fit-content;
            }
            .box-video {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                background-color: #dcdcdc;
                video {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        button {
            color: #FFFFFF;
            border-radius: 25px;
            padding: 5px 15px;
            margin: 5px 5px 20px;
        }
    }

    &-button {
        background-color: #EB4242;

        &-cut {
            background-color: #000000;
        }
    }
}

.ant-modal-body {
    .d-delete-all {
        text-align: center;
        min-height: 32px;

        .btn-delete-all {
            background-color: #EB4242;
            color: #FFFFFF;
            border-radius: 25px;
            padding: 5px 15px;
            width: fit-content;
            margin: 0 auto;
        }
    }
}

@include tablet {
    .drap-drop-image {
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
        margin: 20px 0;
        justify-content: left;

        &__item {
            &-child {
                height: 130px;
                // width: 173px;
                .icon-play-video {
                    top: 53px;
                }
            }
        }
    }
}

@include ipad-air {
    .drap-drop-image {}
}