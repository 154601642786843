.portal-event {
  max-width: 1200px;
  margin: 0 auto 90px;
  text-align: center;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;;
  @include mobile-xxl {
    padding: 0 10px;
  }

  &__banner {
    width: 100%;
    margin-top: 130px;
    padding-top: 10px;
    @include mobile-xxl {
      margin-top: 70px;
    }
    &__bannerPc {
      width: 100%;
      max-height: 400px;
      object-fit: contain;
      @include mobile-xxl {
        display: none;
      }
    }
    &__bannerSp {
      width: 100%;
      height: auto;
      display: none;
      @include mobile-xxl {
        display: block;
      }
    }
  }

  &__content {
    width: 100%;
    padding: 20px;
    border: 2px solid #E9E9E9;
    border-radius: 10px;
    margin: 10px 0;
    box-shadow: 1px 4px 4px #cecdcd;
    @include mobile-xxl {
      padding: 10px 5px 5px 5px;
    }

    &__time {
      text-align: center;
      font-size: 19px;
      font-weight: 400;
      color: #000;
      @include mobile-xxl {
        font-size: 12px;
      }
      &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65%;
        margin: 0 auto;
        @media screen and (max-width: 395px) and (min-width: 375px) {
          width: 95%;
          margin: 5px auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        @media screen and (max-width: 768px) and (min-width: 395px) {
          width: 91%;
          margin: 5px auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        &__title {
          text-align: right;
          width: 26%;
          @include mobile-xxl {
            width: 100%;
            text-align: left;
          }
        }
        span {
          margin: 0 10px;
          @include mobile-xxl {
            margin: 0 6px;
          }
        }
      }
    }

    &__line {
      &--line1 {
        border: 1px dotted #666;
        @include mobile-xxl {
          width: 80%;
          margin: 0 auto;
        }
      }
    }

    &__text {
      &__title {
        font-size: 25px;
        margin-top: 10px;
        margin-bottom: 20px;
        p {
          color: #000;
        }
        @include mobile-xxl {
          font-size: 17px;
          margin-bottom: 1px;
          margin-top: 5px;
        }
      }
      &__comment {
        text-align: left;
        pre {
          font-size: 16px;
          display: block;
          white-space: pre-wrap;
          word-wrap: break-word;
          margin: 1em 0;
          color: #000;
          font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
          @include mobile-xxl {
            font-size: 12px;
            padding: 10px;
          }
        }
      }
    }

    &__participants {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        color: #000;
        margin-right: 10px;
        font-size: 18px;
        @include mobile-xxl {
          font-size: 17px;
        }
      }
    }
  }

  &__point {
    &__title {
      background-color: #000;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 8px 10px 8px 15px;
      border-radius: 70px;
      margin-top: 30px;
      @include mobile-xxl {
        justify-content: space-between;
        padding: 5px 15px;
      }
      p {
        font-size: 24px;
        margin-right: 40px;
      }
      &__icon {
        margin-top: 6px;
        @include mobile-xxl {
         width: 27px;
         height: auto;
         margin-top: 2px;
        }
      }
    }
  
    &__content {
      &__card {
        border: 1px solid #D2D2D2;
        border-radius: 10px;
        margin: 15px 0;
        padding: 15px 0 15px 20px;
        @include mobile-xxl {
          padding: 15px 10px;
        }
      }
      &__modal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include mobile-xxl {
          display: none;
        }
        &__left {
          display: flex;
          align-items: center;
          width: 20%;
          p {
            font-size: 25px;
            font-weight: 700;
            color: #000;
          }
        }
        &__right {
          width: 40%;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mobile-xxl {
            display: none;
          }
          p {
            width: 220px;
            padding: 8px;
            text-align: center;
            border-radius: 25px;
            background-color: #000;
            font-size: 25px;
            font-weight: 600;
            color: #fff;
            @include mobile-xxl {
              font-size: 17px;
              font-weight: 700;
              cursor: pointer;
            }
          }
        }
        &__other {
          width: 40%;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mobile-xxl {
            display: none;
          }
          p {
            color: #000;
            text-align: center;
            display: -webkit-box;
            max-height: 3.2rem;
           -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            -webkit-line-clamp: 2;
            line-height: 1.6rem;
            font-size: 17px;
          }
        }
        &__bonus--top {
          width: 40%;
          height: 80px;
          border-left:1px solid #D2D2D2;
          border-right:1px solid #D2D2D2;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          @include mobile-xxl {
            display: none;
          }
          p {
            font-size: 17px;
            color: #000;
            display: -webkit-box;
            max-height: 3.2rem;
           -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            -webkit-line-clamp: 2;
            line-height: 1.6rem;
            text-align: left;
          }
        }
      }
      &__modal--sp {
        display: none;
        @include mobile-xxl {
          display: block;
          text-align: center;
          color: #000;
        }
        &__left__wrap {
          display: flex;
          margin-bottom: 5px;
          justify-content: space-between;
        }
        &__left {
          display: flex;
          align-items: center;
          color: #000;
          font-size: 17px;
          font-weight: 700;
        }
        &__center--sp {
          width: 60%;
          text-align: left;
        }
        &__right--sp {
          text-align: center;
          border-radius: 25px;
          background-color: #000;
          color: #fff;
          width: 156px;
          padding: 5px;
        }
        &__right {
          width: 100%;
          display: flex;
          justify-content: end;
          border-top: 1px solid #D2D2D2;
          margin-top: 10px;
          padding-top: 10px;
          p {
            text-align: center;
            border-radius: 25px;
            background-color: #000;
            color: #fff;
            width: 156px;
            padding: 5px;
          }
        }
        &__other--sp {
          margin-top: 15px;
          padding: 10px 10px 0 0;
          width: 100%;
          display: flex;
          justify-content: end;
          border-top: 1px solid #D2D2D2;
          p {
            width: 80%;
            color: #000;
            text-align: right;
          }
        }
        &__other {
          width: 48%;
          text-align: center;
        }
      }
      .antd-tooltip-inner {
			min-width: 300px;
			height: fit-content;
      }
    }
  }

  &__submit {
    margin-top: 40px;
    &__active {
      background-color: #000;
      color: #fff;
      padding: 10px 60px;
      border-radius: 7px;
      cursor: pointer;
    }
    &__no-active {
      background-color: #C8C8C8;
      color: #fff;
      padding: 10px 60px;
      border-radius: 7px;
      cursor: no-drop;
    }
  }

  &__modal {
    text-align: center;
    position: relative;
    &__close {
      position: absolute;
      bottom: 110%;
      right: 0;
      color: #000;
      border: none;
      font-size: 20px;
      background-color: transparent;
    }
    &__title {
      font-size: 20px;
      font-weight: 500;
      margin: 20px 0;
      @include mobile-xxl {
        font-size: 18px;
      }
    }
    &__button--ok {
      padding: 10px 50px;
      border: none;
      background-color: #000;
      color: #fff;
    }
    &__button--cancel {
      padding: 10px 30px;
      border: none;
      background-color: #D33828;
      color: #fff;
    }
  }

  &__pickup {
    margin-top: 10px;
    &__ranking {
      &__title {
        display: flex;
        align-items: center;
        background-color: #000;
        color: #fff;
        padding: 8px 10px 8px 15px;
        border-radius: 70px;
        text-align: left;
        @include mobile-xxl {
          justify-content: space-between;
          padding: 10px 15px;
        }
        p {
          font-size: 24px;
          margin-right: 20px;
          @include mobile-xxl {
            font-size: 18px;
          }
        }
      }
      &__button--title {
        width: 100%;
        display: flex;
        align-items: center;
        background-color: #000;
        color: #fff;
        padding: 8px 10px 8px 15px;
        border-radius: 70px;
        @include mobile-xxl {
          justify-content: space-between;
          padding: 10px 15px;
        }
        p {
          font-size: 24px;
          margin-right: 20px;
          @include mobile-xxl {
            font-size: 16px;
          }
        }
        img {
          @include mobile-xxl {
            width: 27px;
            height: auto;
          }
        }
      }

      &__navigate {
        width: 100%;
        text-align: right;
        padding-top: 10px;
        a {
          font-size: 20px;
          text-decoration: underline;
          color: #000;
          margin-right: 10px;
        }
      }

      &__content {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 25px 0;
        width: 100%;
        flex-wrap: wrap;
        &__modal {
          width: 19%;
          height: 220px;
          margin: 0 3%;
          position: relative;
          @include mobile-xxl {
            width: 46%;
            margin: 10px 2%;
          }
          &__award {
            position: absolute;
            top: 0;
            left: 0;
            img {
              @include mobile-xxl {
                width: 40px;
              }
            }
          }
          &__avatar {
            width: 100%;
            height: auto;
            max-height: 220px;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            object-fit: cover;
            cursor: pointer;
          }
          &__name {
            text-align: center;
            background-color: #D9D9D9;
            color: #1C1C1C;
            padding: 8px 3px;
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }

  &__loading {
    height: 700px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .isContent {
    cursor: pointer;
  }
}
.ant-scrolling-effect {
  width: 100% !important;
}
.ranking2--image{
  margin-left: 12px;
  margin-right: 13px;
}
.ranking3--image{
  margin-left: 15px;
  margin-right: 14px;
}
@include mobile-xxl {
  .ranking3--image {
    width: 26px;
    margin-left: 9px;
  }
  .ranking2--image{
    width: 30px;
    margin-left: 6px;
  }
  .ranking1--image {
    width: 44px;
  } 
}
