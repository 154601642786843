.portal__page {
  min-height: 60vh;
  @include mobile {
    padding-top: 70px;
  }
  @include tablet {
    padding-top: 135px;
  }
  @include desktop {
    padding-top: 115px;
  }
  margin: 10px;

  &__preview-warning {
    text-align: center;
    padding: 10px 15px;
    margin-bottom: 10px;
    background-color: var(--color-secondary);
    color: var(--color-white);
    text-transform: uppercase;
    font-weight: bolder;
  }

  &__container {
    background-color: var(--color-white);
    padding: 20px;
    border-radius: 8px;
    box-shadow: rgba(var(--color-black-rgb), .1) 0 0 15px;
    max-width: 1170px;
    margin: 0 auto;
    margin-bottom: 20px;
    @include desktop {
      max-width: 1200px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  &__header {
    margin-bottom: 20px;

    h3 {
      color: var(--color-black);
      margin: 0;
      padding: 0;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: bolder;
    }
  }
}

.portal-static-page {
  & > article {
    img {
      width: 100% !important;
    }
  }
}