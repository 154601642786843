.portal-liveAvailable {
    &__list {
        width: 100%;
    }
    &__item {
        &__container {
            display: inline-block;
            margin-left: 20px;
        }
        &__icon {
            &-container {
                position: relative;
                margin-bottom: 5px;
            }
            &-avatar {
                position: absolute;
                top: 5px;
                left: 5px;
            }
        }
        &__label {
            max-width: 90px;
            p {
                font-size: 12px;
                color: var(--color-black);
                text-align: center;
                cursor: default;
            }
        }
    }
}