.upload-zone {
    background: transparent !important;
    border: 1px dashed var(--color-link) !important;
    height: 150px !important;

    &__note {
        &--highlight {
            color: var(--color-link)
        }
    }
}