.portal-groups-info {
    padding-top: 20px;
    width: 100%;
    color: var(--color-dark);
    @include mobile {
        padding-left: 15px;
        padding-right: 15px;
    }
    &__wrapper {
        margin-bottom: 20px;
    }
    &__header {
        p {
            font-size: 28px !important;
            @include mobile-xxl {
                margin-left: 0px !important;
                font-size: 24px !important;
            }
            font-family: 'Times New Roman', Times, serif;
            font-weight: 700;
            color: var(--color-black);
        }
    }
    &__container {
        @include mobile-xxl {
            margin-left: 0px;
        }
        font-size: 16px;
    }
    &__section {
        &__heading {
            display: flex;
            justify-content: start;
            align-items: center;
            p {
                font-size: 16px;
                color: var(--color-black);
                font-weight: 700 !important;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
            }
        }
        &__content {
            text-align: center;
            p {
                font-size: 14px;
                color: var(--color-dark);
            }
        }
    }
    &__link {
        text-align: left;
        p {
            &:hover,
            &:focus,
            &:active {
                color: var(--color-primary);
            }
        }
    }
    &__profile {
        margin-bottom: 40px;
    }
    &__divider {
        &__top {
            margin: 0 0 10px 0;
        }
        &__bottom {
            margin: 5px 0;
        }
    }
    &__icon {
        svg {
            fill: var(--color-dark);
            width: 14px;
            height: 14px;
        }
        &__panel-header {
            svg {
                fill: var(--color-dark);
                width: 18px;
                height: 18px;
            }
        }
    }
}
