.portal-contact {
    &__content {
        &-heading {
            font-size: 14px;
            margin-top: 10px;
            padding-bottom: 15px;
        }
        &-questions {
            font-size: 14px;
            margin-top: 15px;
            padding-bottom: 15px;
        }
    }
    &__subheading {
        text-align: center;
        margin-top: 20px;
        padding: 7px 0;
        color: var(--color-white);
        background-color: var(--color-black);
        border-radius: 70px;
    }
    &__link {
        margin-top: 5px;
        display: flex;
        justify-content: center;
        &-heading {
            font-size: 16px;
            margin-bottom: 10px;
        }
        &-btn {
            font-size: 14px;
            background-color: grey;
            border: solid 1px var(--color-black);
            box-shadow: none;

            &:hover {
                border: solid 1px var(--color-dark);
                box-shadow: none;
            }
        }
    }
}