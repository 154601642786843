.portal-artist-blogs {
    max-width: 1170px;
    margin: 0 auto;

    @include mobile {
        margin-top: 15px;
    }

    @include desktop {
        margin-top: 25px;
    }

    &__container {
        // background-color: var(--color-white);
        padding: 20px;
        border-radius: 8px;
        box-shadow: rgba(var(--color-black-rgb), 0.1) 0 0 15px;
        margin-bottom: 20px;
    }

    &__header {
        margin-bottom: 20px;

        h3 {
            color: var(--color-dark);
            margin: 0;
            padding: 0;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: bolder;
        }
    }

    &__list {
        &__item {
            &__title {
                font-size: 16px;

                &:hover,
                &:active,
                &:focus {
                    color: var(--color-primary) !important;
                }
            }
        }
    }

    &__load-more {
        background-color: var(--color-black);
        border-color: var(--color-black);
        font-weight: bolder;
        text-transform: uppercase;

        &:focus,
        &:hover,
        &:active {
            background-color: rgba(0, 0, 0, 0.7);
            border-color: rgba(0, 0, 0, 0.7);
        }
    }
}

.popup-blog {
    .ant-btn {
        display: none;
    }

    .ant-btn-primary {
        display: inline-block;
        background-color: var(--color-black);
        border: 1px solid var(--color-black);

        &:hover {
            opacity: 0.7;
        }
    }

    p {
        padding-top: 10px;
        padding-right: 20px;

        @include mobile {
            padding-right: 12px;
        }
    }

    .ant-modal-close-x {
        width: 35px;
        height: 40px;
        line-height: 30px;
    }
}

.skeleton-loading-blog {
    @include desktop {
        width: 1170px;
        margin: 0 auto;
        margin-top: 40px;
    }

    @include mobile {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.blog-load-more-session {
    @include mobile {
        padding: 0 15px;
    }
}

.rank-blog {
    width: 75px;
}

.rank-blog-badge {
    width: 75px;
    margin-top: 3px;
    border-radius: 5px;
    text-align: center;
    background-color: var(--color-black);
    padding-top: 2px;
    padding-bottom: 2px;
}

.ant-badge-status-dot {
    display: none;
}

.ant-badge-status-text {
    color: #ffffff;
    margin-left: 0;
}

.item-listFC {
    @include mobile {
        padding-left: 15px;
        padding-right: 15px;
    }

    justify-content: start;
}


.blog-item-dev {
    display: flex;
    flex-direction: column;

    a {
        color: var(--color-link);
    }

    p {
        // color: #00000073;
        font-size: 14px;

        span {
            // color: #00000073;
            font-weight: 700;
        }
    }

    &__metadata {
        display: flex;

        &--comment-count,
        &--like-count {
            margin-left: 10px;
            display: flex;
            gap: 5px;
        }
    }
}

.title_blog_item {
    font-size: 16px;

    &:hover {
        cursor: pointer;
    }
}

.blog-btn-load-more {
    margin-bottom: 50px;
}